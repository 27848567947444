import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';
import { LayoutEffects, OperadorasEffects, RouterEffects, UserProfileEffects } from './effects';
import { ROOT_REDUCERS, metaReducers } from './reducers';

@NgModule({
  imports: [
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
    }),

    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),

    StoreDevtoolsModule.instrument({
      name: 'Gestor IDSS',
      maxAge: 25,
      logOnly: environment.production,
    }),

    EffectsModule.forRoot([OperadorasEffects, RouterEffects, LayoutEffects, UserProfileEffects]),
  ],
})
export class AppStoreModule {}
