import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { GeralService, GridService } from '@shared/services';
import { Observable, combineLatest } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReadyGuard {
  constructor(
    private geralService: GeralService,
    private gridService: GridService,
  ) {}

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return combineLatest([this.geralService.getDominiosLoaded(), this.gridService.getVisoesLoaded()]).pipe(
      skipWhile(([dominios_loaded, visoes_loaded]) => !dominios_loaded || !visoes_loaded),
      map(([dominios_loaded, visoes_loaded]) => dominios_loaded && visoes_loaded),
    );
  }
}
