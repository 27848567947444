import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <div class="flex justify-content-between align-items-center mx-5 mb-3">
      <div class="text-sm text-bluegray-400">
        Copyright © 2015-{{ year }}
        <a
          class="footer-link text-bluegray-400 hover:text-primary transition-colors transition-duration-200"
          href="https://blendus.com.br"
          target="_blank"
          rel="noopener"
        >
          {{ 'Blendus Tecnologia em Saúde' }}
        </a>
      </div>
      <img src="assets/images/logo-blendus-horizontal-escuro.svg" width="95" height="24" alt="Logo Blendus" />
    </div>
  `,
})
export class AppFooterComponent {
  year: number = new Date().getFullYear();
}
