<div class="w-full">
  <div class="p-2 text-lg text-center">
    Visões
    <i
      class="pi pi-info-circle cursor-pointer ml-1 text-sm"
      (click)="op.toggle($event)"
      [attr.data-tooltip]="tooltipKey"
    ></i>
  </div>
  <p-overlayPanel #op>
    <ng-template pTemplate>
      <div class="tooltip-overlay" [innerHTML]="tooltipHtml | safeHtml">
        Você pode editar o nome da visão e as configurações de colunas da tabela antes de salvar.
      </div>
      <div class="mt-4" *ngIf="tooltipUrl && (suporteVisible$ | async)">
        <a class="text-primary" [href]="tooltipUrl" target="_blank" rel="noopener">
          Documentação
          <i class="pi pi-external-link text-sm ml-1"></i>
        </a>
      </div>
      <div class="mt-2 text-sm">
        <a title="Editar este conteúdo" class="link" href="{{ ORIENTACOES_EDIT_URL }}" target="_blank" rel="noopener">
          Editar
        </a>
      </div>
    </ng-template>
  </p-overlayPanel>
  <div>
    <form>
      <div class="flex p-2 border-top-1 border-black-alpha-20">
        <input
          pInputText
          placeholder="Nome da visão"
          class="w-10 view-name-input"
          [(ngModel)]="inputName"
          [ngModelOptions]="{ standalone: true }"
        />

        <div class="actions flex w-2">
          <ng-container *ngIf="!viewEditing">
            <button
              class="p-button p-button-text p-button-sm pi pi-plus"
              title="Adicionar"
              [disabled]="!inputName?.trim()"
              (click)="createView()"
            ></button>
            <button
              type="button"
              class="p-button p-button-text p-button-sm p-button-secondary pi pi-undo"
              title="Resetar"
              (click)="resetView()"
            ></button>
          </ng-container>
          <ng-container *ngIf="viewEditing">
            <button
              class="p-button p-button-text p-button-sm pi pi-save"
              title="Atualizar"
              [disabled]="!inputName?.trim()"
              (click)="updateView()"
            ></button>
            <button
              type="button"
              class="p-button p-button-text p-button-sm p-button-secondary pi pi-times"
              title="Cancelar"
              (click)="cancelEditView()"
            ></button>
          </ng-container>
        </div>
      </div>
    </form>
    <div class="p-2 border-top-1 border-black-alpha-20">
      <div *ngIf="!viewsList.length" class="text-center">- sem visões -</div>
      <div class="drag-list">
        <div
          *ngFor="let view of viewsList; let i = index"
          pDraggable="views"
          pDroppable="views"
          dragHandle=".drag-handle"
          (onDragStart)="onDragStart(i)"
          (onDragEnter)="onDragEnter(i)"
          (onDrop)="onDrop(i)"
          class="drag-wrapper"
          [ngClass]="{
            'drag-source': i == dragStartIndex,
            'drag-target-up': i == dragEnterIndex && dragEnterIndex < dragStartIndex,
            'drag-target-down': i == dragEnterIndex && dragEnterIndex > dragStartIndex
          }"
        >
          <div class="drag-drop-area drag-drop-area-up"></div>
          <div class="drag-item flex w-full gap-2" [ngClass]="{ editing: view == viewEditing }">
            <i class="drag-handle ag-icon ag-icon-grip"></i>
            <div class="flex w-full flex-column gap-2">
              <div class="bold">
                {{ view.nm_visao }}
              </div>
              <div class="actions flex justify-content-end gap-2">
                <!--
                <p-toggleButton
                  class="p-button-sm"
                  title="Fixado"
                  [(ngModel)]="view.ib_fixado"
                  (onChange)="onPinnedToggle(view)"
                  onIcon="pi pi-check"
                  offIcon="pi pi-times"
                ></p-toggleButton>
                -->
                <p-checkbox
                  class="p-button-sm"
                  title="Fixado"
                  [(ngModel)]="!!view.ib_fixado"
                  [binary]="true"
                  (onChange)="onPinnedToggle(view)"
                ></p-checkbox>
                <button
                  class="p-button p-button-text p-button-sm pi pi-pencil"
                  title="Editar"
                  (click)="editView(view)"
                ></button>
                <button
                  class="p-button p-button-text p-button-sm p-button-danger pi pi-trash"
                  title="Remover"
                  (click)="removeView(view)"
                ></button>
              </div>
            </div>
          </div>
          <div class="drag-drop-area drag-drop-area-down"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog [style]="{ width: '500px' }" [baseZIndex]="10000"></p-confirmDialog>
