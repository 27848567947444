import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DominioItem, Notificacao } from '@core/api/ords/model';
import { GeralService, NotificacaoService } from '@shared/services';

const DOMINIO_NOTIFICACAO = 'APL_TIPO_NOTIFICACAO';

@Component({
  selector: 'bl-notificacao-dialog',
  templateUrl: './notificacao-dialog.component.html',
  styleUrls: ['./notificacao-dialog.component.scss'],
})
export class NotificacaoDialogComponent implements OnChanges {
  @Input() visible: boolean;
  @Input() notificacao: Notificacao;

  @Output() onClose = new EventEmitter<Event>();

  tipo: DominioItem;

  constructor(
    private service: NotificacaoService,
    private geralService: GeralService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['notificacao']) {
      if (this.notificacao) {
        this.tipo = this.geralService.getDominioItem(this.notificacao.tp_notificacao, DOMINIO_NOTIFICACAO);
      } else {
        this.tipo = null;
      }
    }
  }

  onDialogClose($event: Event) {
    this.onClose.emit($event);
  }

  arquivar() {
    this.service.marcarArquivado(this.notificacao);
    this.visible = false;
    this.onClose.emit(null);
  }
}
