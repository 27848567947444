import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppAuthModule } from '@core/modules';
import { AppStoreModule } from '@core/store/store.module';
import { MessageService } from 'primeng/api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout';
import { PublicModule } from './pages/public';
import { LoaderInterceptorService } from './shared/services';

import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';

import 'ag-grid-enterprise';

const LoaderInterceptor = { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true };

registerLocaleData(pt);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    PublicModule,
    AppRoutingModule,
    AppStoreModule,
    AppAuthModule,
  ],
  declarations: [AppComponent],
  providers: [
    MessageService,
    LoaderInterceptor,
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
