import { Injectable } from '@angular/core';
import { IdssAnsApi, SimuladorApi } from '@core/api/ords';
import { OperadorasActions } from '@core/store/actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, map, mergeMap, skipWhile, switchMap } from 'rxjs/operators';
import { OperadorasSelectors, UserProfileSelectors } from '../selectors';

@Injectable()
export class OperadorasEffects {
  loadOperadoras$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperadorasActions.getOperadoras),
      switchMap(() => this.store.select(UserProfileSelectors.selectUserProfile)),
      skipWhile(({ idss_loaded }) => !idss_loaded),
      mergeMap(() => {
        const operadorasGeral$ = this.api.fetchOperadoras().pipe(map(response => response.items));
        const operadorasClientes$ = this.api.fetchOperadoras({ cliente: 1 }).pipe(map(response => response.items));
        const operadorasWorkspace$ = this.store.select(UserProfileSelectors.selectNivelAcesso).pipe(
          switchMap(nivelAcesso => {
            switch (nivelAcesso) {
              case 'A':
                return operadorasClientes$;
              case 'O':
                return this.store.select(UserProfileSelectors.selectOperadorasVinculadas);
              default:
                return of([]);
            }
          })
        );
        return combineLatest([operadorasGeral$, operadorasClientes$, operadorasWorkspace$]);
      }),
      map(([operadorasGeral, operadorasClientes, operadorasWorkspace]) =>
        OperadorasActions.getOperadorasSuccess({ operadorasGeral, operadorasClientes, operadorasWorkspace })
      ),
      catchError(() =>
        of(
          OperadorasActions.getOperadorasFailure({ errorMsg: 'Ocorreu um erro ao buscar as operadoras na API do ORDS' })
        )
      )
    )
  );

  changeSelectedOperadoraWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperadorasActions.changeSelectedOperadoraWorkspace),
      mergeMap(({ cd_registro_ans }) =>
        this.store.select(OperadorasSelectors.findWorkspaceOperadoraById(cd_registro_ans))
      ),
      mergeMap(operadora =>
        combineLatest([
          of(operadora),
          operadora ? this.simuladorApi.fetchParametorsOperadora(operadora.cd_registro_ans) : of(null),
        ])
      ),
      map(([operadora, params]) =>
        operadora
          ? OperadorasActions.changeSelectedOperadoraWorkspaceSuccess({ operadora, params })
          : OperadorasActions.changeSelectedOperadoraWorkspaceFailed()
      )
    )
  );

  changeSelectedOperadoraDadosSetor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperadorasActions.changeSelectedOperadoraDadosSetor),
      mergeMap(({ cd_registro_ans }) => this.store.select(OperadorasSelectors.findOperadoraById(cd_registro_ans))),
      map(operadora => OperadorasActions.changeSelectedOperadoraDadosSetorSuccess({ operadora }))
    )
  );

  constructor(
    private store: Store,
    private api: IdssAnsApi,
    private simuladorApi: SimuladorApi,
    private actions$: Actions
  ) {}
}
