import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'pontuacao',
})
export class PontuacaoPipe implements PipeTransform {
  @memo()
  transform(value: any) {
    if (value == null) {
      return '';
    } else if (value < 0.2) {
      return 'pontuacao_0_0-0_2';
    } else if (value < 0.4) {
      return 'pontuacao_0_2-0_4';
    } else if (value < 0.6) {
      return 'pontuacao_0_4-0_6';
    } else if (value < 0.8) {
      return 'pontuacao_0_6-0_8';
    } else if (value >= 0.8) {
      return 'pontuacao_0_8-1_0';
    } else {
      return '';
    }
  }
}
