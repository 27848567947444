import { Component, OnDestroy } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { Page } from '../pagination/pagination.component';

@Component({
  selector: 'bl-ag-grid-pagination-status-bar',
  templateUrl: './ag-grid-pagination-status-bar.component.html',
  styleUrls: ['./ag-grid-pagination-status-bar.component.scss'],
})
export class AgGridPaginationStatusBar implements IStatusPanelAngularComp, OnDestroy {
  params: IStatusPanelParams & AgGridPaginationStatusParams;

  from: number = null;
  to: number = null;

  constructor() {}

  // This method is called once.
  agInit(params: IStatusPanelParams & AgGridPaginationStatusParams): void {
    this.params = params;
    params.pagination$
      .pipe(
        tap(data => {
          // console.log('[PaginationStatusBar] data', data);
          this.from = data?.offset != null ? data.offset : null;
          this.to = data?.endOffset != null ? Math.min(+data.offset + +data.limit, data.endOffset) : null;
        }),
        takeUntil(this.ngDestroyed$),
      )
      .subscribe();
  }

  private ngDestroyed$ = new Subject<void>();
  ngOnDestroy() {
    // console.log('[PaginationStatusBar] onDestroy');
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }
}

export type PaginationStatus = Pick<Page, 'limit' | 'offset' | 'endOffset'>;

export interface AgGridPaginationStatusParams {
  pagination$: Observable<PaginationStatus>;
}
