import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'cnpj',
})
export class CNPJPipe implements PipeTransform {
  @memo()
  transform(cnpjValue: any) {
    if (!cnpjValue) {
      return '';
    }

    const cnpj = cnpjValue.replace(/[^\d]/g, '');
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
}
