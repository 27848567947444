import { GridVisao } from '@core/api/ords/model';
import { GridService } from '@shared/services/grid.service';
import { of } from 'rxjs';

export interface AgViewsToolpanelParams {
  gridKey: string;
  hide?: boolean;
}

export class GridSessionStorageService extends GridService {
  readonly STORAGE_PREFIX = 'bl.ag-grid.views.';

  constructor() {
    super(null);
  }

  _getVisoes(key: string) {
    const viewsMaybe = sessionStorage.getItem(this.STORAGE_PREFIX + key);
    if (viewsMaybe) {
      return JSON.parse(viewsMaybe) as GridVisao[];
    }
    return [];
  }

  getVisoes(key: string) {
    const visoes = this._getVisoes(key);
    return of({
      total: visoes.length,
      visoes,
      fixas: visoes.filter(v => v.ib_fixado),
      naoFixas: visoes.filter(v => !v.ib_fixado),
    });
  }

  createVisao(view: GridVisao) {
    const key = view.cd_grade;
    const viewsList = this._getVisoes(key);
    const viewIndex = viewsList.findIndex(v => v.nm_visao == view.nm_visao);
    if (viewIndex >= 0) {
      viewsList.splice(viewIndex, 1, view);
    } else {
      viewsList.push(view);
    }
    this.sortViews(viewsList);
    sessionStorage.setItem(this.STORAGE_PREFIX + key, JSON.stringify(viewsList));
    return of(view);
  }

  deleteVisao(view: GridVisao) {
    const key = view.cd_grade;
    const viewsList = this._getVisoes(key);
    const viewIndex = viewsList.findIndex(v => v.nm_visao == view.nm_visao);
    if (viewIndex >= 0) {
      viewsList.splice(viewIndex, 1);
      sessionStorage.setItem(this.STORAGE_PREFIX + key, JSON.stringify(viewsList));
    }
    return of();
  }

  updateVisao(visao: GridVisao) {
    return this.createVisao(visao);
  }

  reorderVisoes(visoes: GridVisao[]) {
    this.getVisoes(visoes[0].cd_grade);
    return of();
  }

  private sortViews(viewList: GridVisao[]): GridVisao[] {
    return viewList.sort((v1, v2) => v1.nm_visao.localeCompare(v2.nm_visao, undefined, { sensitivity: 'accent' }));
  }
}
