import { DatePipe, DecimalPipe } from '@angular/common';
import { CompetenciaPipe } from '@shared/pipes/competencia.pipe';
import { CpfCnpjPipe } from '@shared/pipes/cpf-ou-cnpj.pipe';
import { CellClassParams, ColDef, GridOptions, ToolPanelDef } from 'ag-grid-enterprise';
import { AgViewsToolpanelComponent, AgViewsToolpanelParams, AG_GRID_LOCALE_PT_BR } from '..';

const DEFAULT_GROUP_COL_WIDTH = 128;

export type COLUMN_TYPES = 'int' | 'money' | 'float1' | 'float4';

export type PIPES = {
  date?: DatePipe;
  decimal?: DecimalPipe;
  competencia?: CompetenciaPipe;
  cpfCnpj?: CpfCnpjPipe;
};

export type AGG_FUNC = 'sum' | 'min' | 'max' | 'count' | 'avg' | 'first' | 'last';

// Ref.: https://ag-grid.zendesk.com/hc/en-us/articles/360016033891-Styling-Pivot-totals
export function isPivotTotalCell(colDef: ColDef): boolean {
  return colDef.pivotTotalColumnIds && colDef.pivotTotalColumnIds.length > 0 && !colDef.pivotKeys.length;
}

export function isFooterTotalCell(params: CellClassParams): boolean {
  // console.log('isFooterTotalCell', params.node.footer, params);
  return params.node.footer;
}

/**
 * Constrói as definições padrão das colunas do AG Grid.
 *
 * @param overrides sobrescreve configurações específicas de padrão das colunas.
 * @returns definição da coluna padrão.
 */
export function buildDefaultColumnDef<TData = any>(overrides?: ColDef<TData>): ColDef<TData> {
  const colDefault: ColDef<TData> = {
    initialWidth: 150,
    filter: true,
    sortable: true,
    resizable: true,
    enableValue: true,
    enablePivot: true,
    enableRowGroup: true,
    cellClassRules: {},
    ...overrides,
  };
  // Reinforce total styling, after overrides
  colDefault.cellClassRules['pivot-total-col'] = params => isPivotTotalCell(params.colDef);
  colDefault.cellClassRules['footer-total-col'] = params => isFooterTotalCell(params);
  return colDefault;
}

/**
 * Constrói as definições padrão das colunas automáticas de grupos de linhas.
 *
 * @param overrides sobrescreve configurações específicas da coluna.
 * @returns definição da coluna automática de grupo.
 */
export function buildAutoGroupColumnDef<TData = any>(overrides?: ColDef<TData>): ColDef<TData> {
  return {
    initialWidth: DEFAULT_GROUP_COL_WIDTH,
    sortable: true,
    sort: 'asc',
    wrapText: true,
    autoHeight: true,
    filter: 'agGroupColumnFilter',
    cellRendererParams: {
      suppressCount: true, // ex: Group Name ([count]) -> Group Name
    },
    ...overrides,
  } as ColDef<TData>;
}

/**
 * Constrói as definidções dos tipos de colunas padrão, conforme  as pipes declaradas.
 *
 * @param pipes objeto de configurações de pipes, caso tenha.
 * @returns tipos de colunas padrão.
 */
export function buildColumnTypes<TData>(pipes?: PIPES): { [key: string]: ColDef<TData> } {
  const columnTypes: { [key: string]: ColDef<TData> } = {
    str: {
      cellClass: 'str',
    },
  };
  if (pipes?.decimal) {
    Object.assign(columnTypes, {
      int: {
        cellClass: 'int',
        cellStyle: { 'text-align': 'right' },
        enableValue: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: params => pipes.decimal.transform(params.value, '1.0-0'),
      },
      money: {
        cellClass: 'money',
        cellStyle: { 'text-align': 'right' },
        enableValue: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: params => pipes.decimal.transform(params.value, '1.2-2'),
      },
      float: {
        cellClass: 'float',
        cellStyle: { 'text-align': 'right' },
        enableValue: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: params => pipes.decimal.transform(params.value, '1.0-3'),
      },
      float1: {
        cellClass: 'float1',
        cellStyle: { 'text-align': 'right' },
        enableValue: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: params => pipes.decimal.transform(params.value, '1.1-1'),
      },
      float4: {
        cellClass: 'float4',
        cellStyle: { 'text-align': 'right' },
        enableValue: true,
        filter: 'agNumberColumnFilter',
        valueFormatter: params => pipes.decimal.transform(params.value, '1.4-4'),
      },
    });
  }
  if (pipes?.date) {
    Object.assign(columnTypes, {
      date: {
        cellClass: 'date',
        valueFormatter: params => pipes.date.transform(params.value, 'shortDate'),
        getQuickFilterText: params => pipes.date.transform(params.value, 'shortDate'),
      },
      datetime: {
        cellClass: 'datetime',
        valueFormatter: params => pipes.date.transform(params.value, 'short'),
        getQuickFilterText: params => pipes.date.transform(params.value, 'short'),
      },
    });
  }
  if (pipes?.competencia) {
    Object.assign(columnTypes, {
      competencia: {
        cellClass: 'competencia',
        valueFormatter: params => pipes.competencia.transform(params.value),
        getQuickFilterText: params => pipes.competencia.transform(params.value),
      },
    });
  }
  if (pipes?.cpfCnpj) {
    Object.assign(columnTypes, {
      cpfCnpj: {
        cellClass: 'cpfCnpj',
        valueFormatter: params => pipes.cpfCnpj.transform(params.value),
        getQuickFilterText: params => pipes.cpfCnpj.transform(params.value),
      },
      cpf: {
        cellClass: 'cpf',
        valueFormatter: params => pipes.cpfCnpj.transform(params.value),
        getQuickFilterText: params => pipes.cpfCnpj.transform(params.value),
      },
      cnpj: {
        cellClass: 'cnpj',
        valueFormatter: params => pipes.cpfCnpj.transform(params.value),
        getQuickFilterText: params => pipes.cpfCnpj.transform(params.value),
      },
    });
  }
  return columnTypes;
}

/**
 * Parâmetros básicos do construtor de configurações do AG Grid.
 */
export interface BuildConfigOptions<TData = any>
  extends Pick<GridOptions<TData>, 'gridId' | 'columnDefs' | 'pivotMode'> {
  pipes?: PIPES;
  exportExcelFn?: () => void;
  views?: AgViewsToolpanelParams;
}

/**
 * Construtor das configurações do AG Grid.
 *
 * Centraliza e compartilha as configurações padrão entre as telas
 * que utilizam o AG Grid.
 *
 * @param buildOptions parâmetros de configurações básicas
 * @param overrides sobrescreve configurações específicas do AG Grid.
 * @returns objeto de configurações do AG Grid.
 */
export function buildGridOptions<TData = any>(
  buildOptions: BuildConfigOptions<TData>,
  overrides?: GridOptions<TData>,
): GridOptions<TData> {
  let groupFooter = overrides?.groupTotalRow ?? null;
  let totalFooter = overrides?.grandTotalRow ?? 'bottom';

  const toolPanels: (string | ToolPanelDef)[] = ['columns', 'filters'];

  if (buildOptions.views && !buildOptions.views.hide) {
    toolPanels.push({
      id: 'views',
      labelDefault: 'Visões',
      labelKey: 'viewsLabel',
      iconKey: 'menu',
      toolPanel: AgViewsToolpanelComponent,
      toolPanelParams: {
        columns: buildOptions.columnDefs,
        ...buildOptions.views,
      },
    });
  }

  const onFirstDataRenderedOverride = overrides?.onFirstDataRendered;
  if (onFirstDataRenderedOverride) {
    delete overrides.onFirstDataRendered;
  }

  const onColumnRowGroupChangedOverride = overrides?.onColumnRowGroupChanged;
  let columnRowGroupWidths: { key: string; newWidth: number }[] = [];
  if (onColumnRowGroupChangedOverride) {
    delete overrides.onColumnRowGroupChanged;
  }

  const gridOptions: GridOptions<TData> = {
    // Column Definintions
    columnDefs: buildOptions.columnDefs,
    columnTypes: buildColumnTypes(buildOptions.pipes),
    defaultColDef: buildDefaultColumnDef(),
    autoGroupColumnDef: buildAutoGroupColumnDef(),

    // Groups (row)
    groupDisplayType: 'multipleColumns',
    groupHideOpenParents: true, // single row for multiple group columns
    groupDefaultExpanded: -1, // all expanded
    grandTotalRow: 'bottom', // bottom total
    suppressAggFuncInHeader: true, // ex: sum(Header Name) -> Header Name
    rowGroupPanelShow: 'onlyWhenGrouping', // top panel

    // Pivot (column)
    pivotMode: buildOptions.pivotMode,
    pivotRowTotals: 'after', // right total
    pivotDefaultExpanded: -1,
    processPivotResultColDef: colDef => {
      if (isPivotTotalCell(colDef)) {
        colDef.headerClass = 'pivot-total-header';
      }
    },

    // Other
    headerHeight: 45, // double line header
    enableRangeSelection: true, // excel-like selection
    enableCharts: true, // context menu charts
    cacheQuickFilter: true,
    sideBar: {
      toolPanels,
    },
    statusBar: {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        // { statusPanel: 'agTotalRowCountComponent', align: 'center' },
        // { statusPanel: 'agFilteredRowCountComponent', align: 'center' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' },
      ],
    },

    // Custom Export from Context Menu
    getContextMenuItems: () => {
      return [
        'copy',
        'copyWithHeaders',
        'copyWithGroupHeaders',
        'separator',
        {
          name: 'Limpar Filtros',
          icon: '<span class="ag-icon ag-icon-filter"></span>',
          action: params => params.api.setFilterModel(null),
        },
        'expandAll',
        'contractAll',
        'autoSizeAll',
        'resetColumns',
        'separator',
        {
          name: 'Exibir/Esconder Sub-Total por Grupo',
          icon: '<span class="ag-icon ag-icon-aggregation"></span>',
          action: params => {
            groupFooter = !groupFooter ? 'bottom' : null;
            params.api.updateGridOptions({ groupTotalRow: groupFooter });
          },
        },
        {
          name: 'Exibir/Esconder Grand-Total',
          icon: '<span class="ag-icon ag-icon-aggregation"></span>',
          action: params => {
            totalFooter = !totalFooter ? 'bottom' : null;
            params.api.updateGridOptions({ grandTotalRow: totalFooter });
          },
        },
        'separator',
        'chartRange',
        'pivotChart',
        {
          name: 'Exportar',
          icon: '<span class="ag-icon ag-icon-save"></span>',
          subMenu: [
            'csvExport',
            !buildOptions.exportExcelFn
              ? 'excelExport'
              : {
                  name: 'Exportar para Excel (com filtros)',
                  icon: '<span class="ag-icon ag-icon-excel"></span>',
                  action: () => buildOptions.exportExcelFn(),
                },
          ],
        },
      ];
    },

    // Events
    onFirstDataRendered: event => {
      // console.log('[Grid] onFirstDataRendered');

      if (onFirstDataRenderedOverride) {
        onFirstDataRenderedOverride(event);
      }
    },

    onColumnRowGroupChanged: event => {
      // console.log('[Grid] onColumnRowGroupChanged', event);

      if (onColumnRowGroupChangedOverride) {
        onColumnRowGroupChangedOverride(event);
      }
    },

    onModelUpdated: event => {
      // console.log('[Grid] onModelUpdated', event);

      const state = event.api.getColumnState();
      // console.log('...state', state);

      const groupCols = state.filter(col => col.colId.startsWith('ag-Grid-AutoColumn-'));
      if (groupCols.find(col => col.width != DEFAULT_GROUP_COL_WIDTH)) {
        columnRowGroupWidths = state.map(col => ({ key: col.colId, newWidth: col.width }));
        // console.log('SAVE WIDTHS:', columnRowGroupWidths);
      } else {
        // console.log('RESTORE WIDTHS:', columnRowGroupWidths);
        event.api.setColumnWidths(columnRowGroupWidths);
      }
    },

    // Localisation
    localeText: AG_GRID_LOCALE_PT_BR,

    // Excel styles
    excelStyles: [],

    // Config overrides
    ...overrides,
  };

  // Add fixed styles for excel, after overrides
  gridOptions.excelStyles.push({
    id: 'pivot-total-col',
    interior: {
      pattern: 'Solid',
      color: '#eeeeee',
    },
  });
  gridOptions.excelStyles.push({
    id: 'footer-total-col',
    interior: {
      pattern: 'Solid',
      color: '#eeeeee',
    },
  });
  gridOptions.excelStyles.push({
    id: 'int',
    dataType: 'Number',
    numberFormat: {
      format: '0',
    },
  });
  gridOptions.excelStyles.push({
    id: 'money',
    dataType: 'Number',
    numberFormat: {
      format: '#,##0.00',
    },
  });
  gridOptions.excelStyles.push({
    id: 'float',
    dataType: 'Number',
    numberFormat: {
      format: '0.0',
    },
  });
  gridOptions.excelStyles.push({
    id: 'float1',
    dataType: 'Number',
    numberFormat: {
      format: '0.0',
    },
  });
  gridOptions.excelStyles.push({
    id: 'float4',
    dataType: 'Number',
    numberFormat: {
      format: '0.0000',
    },
  });
  gridOptions.excelStyles.push({
    id: 'date',
    dataType: 'DateTime',
    numberFormat: {
      format: 'dd/mm/yyyy',
    },
  });
  gridOptions.excelStyles.push({
    id: 'datetime',
    dataType: 'DateTime',
    numberFormat: {
      format: 'dd/mm/yyyy HH:mm:ss',
    },
  });
  gridOptions.excelStyles.push({
    id: 'str',
    dataType: 'String',
  });
  gridOptions.excelStyles.push({
    id: 'competencia',
    dataType: 'Number',
    numberFormat: {
      format: '0000-00',
    },
  });
  gridOptions.excelStyles.push({
    id: 'cpfCnpj',
    dataType: 'String',
  });
  gridOptions.excelStyles.push({
    id: 'cpf',
    dataType: 'String',
    /*
    dataType: 'Number',
    numberFormat: {
      format: '000\\.000\\.000-00',
    },
    */
  });
  gridOptions.excelStyles.push({
    id: 'cnpj',
    dataType: 'String',
    /*
    dataType: 'Number',
    numberFormat: {
      format: `00\\.000\\.000\\/0000-00`,
    },
    */
  });

  return gridOptions;
}

// Clear these properties
export const CLEAR_VIEW: ColDef = {
  pivotIndex: null,
  rowGroupIndex: null,
  aggFunc: null,
  hide: null,
};

/**
 * Opção de configuração das visões.
 */
export interface ViewOptions {
  pivots: string[];
  linhas: string[];
  metricas: string[] | Record<string, AGG_FUNC>;
}

/**
 * Constrói definições de colunas, partindo das definições base,
 * conforme as configurações de visões especificadas.
 *
 * @param baseColDef definições base das colunas.
 * @param options configurações da visão.
 * @returns definições de colunas de acordo com as configurações da visão.
 */
export function buildViewColDefs(baseColDef: ColDef[], options?: ViewOptions): ColDef[] {
  if (!options) {
    return baseColDef.map(col => ({
      ...CLEAR_VIEW,
      ...col,
    }));
  }

  const { pivots, linhas, metricas } = options;

  let metricasFields: string[];
  let metricasMap: Record<string, AGG_FUNC>;
  if (Array.isArray(metricas)) {
    metricasFields = metricas;
    metricasMap = metricas.reduce((acc, metrica) => {
      acc[metrica] = 'sum';
      return acc;
    }, {});
  } else {
    metricasFields = Object.keys(metricas);
    metricasMap = metricas;
  }

  const visible = [].concat(pivots, linhas, metricasFields);

  // TODO metric order?

  return baseColDef.map(col => ({
    ...CLEAR_VIEW,
    ...col,
    pivotIndex: pivots.includes(col.field) ? pivots.indexOf(col.field) : null,
    rowGroupIndex: linhas.includes(col.field) ? linhas.indexOf(col.field) : null,
    aggFunc: col.field in metricasMap ? metricasMap[col.field] : null,
    hide: visible.includes(col.field) ? null : true,
  }));
}
