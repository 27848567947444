<div class="pontuacao">
  <div class="nao-pontuado" *ngIf="!pontuado">
    <span *ngIf="idPrecisao == 'NP'">Não Pontuado</span>
    <span *ngIf="idPrecisao == 'NA'">Não Aplicável</span>
  </div>

  <div class="pontuado" *ngIf="pontuado">
    <div *ngIf="vlPontuacao != null">
      <div>
        <span>
          {{ vlPontuacao | number: '1.4' }}
        </span>

        <span title="Inconsistente" class="inconsistente" *ngIf="inconsistente"> INC </span>

        <ng-container *ngIf="tendencia && vlPontuacao !== 0">
          <span [title]="tendencia?.title" [class]="tendencia?.icon" [style.color]="tendencia?.color"> </span>
          <span class="delta" *ngIf="pcDeltaPrevia != null"> {{ pcDeltaPrevia | number: '1.1-2' }} %</span>
        </ng-container>
      </div>
      <div [class]="vlPontuacao | pontuacao"></div>
    </div>

    <!-- Regra do Simulador (?) -->
    <div *ngIf="vlPontuacao == null">{{ idPrecisao }}</div>
  </div>
</div>
