<div class="politica" style="height: 400px; overflow: auto">
  <h2>OBJETIVO</h2>

  <p>
    A presente Política de Privacidade tem a finalidade de demonstrar transparência quanto a coleta de informações, uso,
    armazenamento, tratamento e proteção dos dados pessoais dos usuários do site app.idss.com.br, e esclarecer os
    motivos da coleta e a forma como os usuários podem gerenciar ou excluir as suas informações pessoais.
  </p>

  <h2>ABRANGÊNCIA</h2>

  <p>
    Esta política de Privacidade aplica-se a todos os usuários dos sistemas da Blendus, devidamente inscrita no CNPJ sob
    o nº 00.485.171/0001-58, situado em Florianópolis/SC, na Av. Rio branco, 404, doravante nominada BLENDUS TECNOLOGIA
    EM SAÚDE LTDA.
  </p>

  <h2>METODOLOGIA</h2>

  <h3>Quanto à coleta de informações e uso.</h3>

  <p>
    Ao se cadastrar no sistema Gestor IDSS, o usuário precisa fornecer os seguintes dados: nome completo, CPF, endereço
    de e-mail, CNPJ da Operadora de Saúde, Registro ANS da Operadora de Saúde, cargo, telefone celular e telefone
    comercial. Após validar o seu e-mail, o usuário deverá definir uma senha de acesso. O e-mail e senha de acesso
    compõem as credenciais de acesso.
  </p>

  <p>
    Além dos dados cadastrais para criação de credenciais de acesso, a Blendus também coleta informações de forma
    automática a cada conexão e iteração do usuário com o sistema, como por exemplo: IP comercial de acesso à internet
    por parte do usuário (com data e hora). Tais dados coletados tem por objetivo identificar o acesso para fins de
    registro de LOG para segurança dos servidores de aplicação e rastreabilidade das ações dos usuários e principalmente
    download de arquivos da operadora a qual o usuário está vinculado. Consideramos a operadora como o Controlador dos
    dados.
  </p>

  <p>
    As informações pessoais do usuário serão coletadas, usadas e tratadas, única e exclusivamente, para que as
    funcionalidades contratadas sejam efetivamente usadas pelo mesmo; e serão armazenadas em ambiente seguro e de acesso
    restrito, atendendo a preservação da intimidade, da vida privada, da honra e da imagem das partes direta ou
    indiretamente envolvidas.
  </p>

  <h3>Quanto ao armazenamento e exclusão de informações.</h3>

  <p>
    Para proteger os dados dos usuários, a Blendus conta com Criptografia (SSL) da Globe Standard SSL para estabelecer
    conexão HTTPS.
  </p>

  <p>
    Caso o usuário deseje consultar ou alterar seus dados cadastrais, basta acessar o seu perfil quando estiver logado
    no sistema Gestor IDSS, sendo permitido a alteração de dados como: cargo e telefones. Em caso de dúvidas, entre em
    contato através do suporte da Blendus pelo e-mail contato@blendus.com.br.
  </p>

  <p>
    Se mesmo assim o usuário desejar removê-las, primeiramente deverá solicitar à equipe Blendus para avaliação dos
    dados cadastrais e registros de auditoria que estão atrelados aos processos de qualificação das informações enviadas
    pela Operadora da Saúde (da qual faz parte como colaborador). A operadora vinculada ao usuário também será
    consultada, para que seja possível a exclusão ou inativação dos dados cadastrais do usuário no sistema Gestor IDSS.
  </p>

  <h3>Quanto ao compartilhamento.</h3>

  <p>
    As informações do usuário nunca serão fornecidas a nenhuma outra empresa que não a Blendus e a própria Operadora na
    qual o usuário está vinculado.
  </p>

  <p>
    A Blendus poderá enviar ao e-mail dos usuários mensagens de aviso/comunicação relativos aos processos e
    funcionalidades do sistema Gestor IDSS, para confirmação de alguma ação ou para informar sobre alguma ação que
    precisa ser executada pelo usuário. Não utilizamos os dados cadastrados de usuários para outras ações, como
    newsletters ou mensagens publicitárias.
  </p>

  <h2>ALTERAÇÃO NA POLÍTICA DE PRIVACIDADE</h2>

  <p>A Blendus reserva-se o direito de, a qualquer momento, modificar esta Política.</p>

  <h2>LEGISLAÇÃO APLICÁVEL</h2>

  <p>
    O presente documento foi elaborado em conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18).
  </p>

  <footer>Última atualização deste documento: 13/10/2020.</footer>
  <p-scrollTop target="parent"></p-scrollTop>
</div>
