import {
  ExcelCell,
  DateFilterModel,
  TextFilterModel,
  NumberFilterModel,
  ICombinedSimpleModel,
  ISimpleFilterModel,
  GridApi,
  ExcelRow,
} from 'ag-grid-community';
import { SetFilterModel } from 'ag-grid-enterprise';

export type AgFilterModel =
  | ISimpleFilterModel
  | NumberFilterModel
  | TextFilterModel
  | DateFilterModel
  | SetFilterModel
  | ICombinedSimpleModel<ISimpleFilterModel>;

const COMPARISON_TEXT = {
  equals: '=',
  notEqual: '!=',
  contains: 'contém',
  notContains: 'não contém',
  startsWith: 'começa com',
  endsWith: 'termina com',
  lessThan: '<',
  lessThanOrEqual: '<=',
  greaterThan: '>',
  greaterThanOrEqual: '>=',
  inRange: 'no intervalo',
  empty: 'vazio',
};

export class AgFilterUtils {
  private constructor() {}

  static competenciaRangeToString(ranges: string[]): string {
    return ranges.reduce(
      (acc, range) => (acc ? acc + ' até ' : '') + (range.substring(0, 4) + '/' + range.substring(4, 6)),
      '',
    );
  }

  static buildFilterRows(api: GridApi, header?: string[], extraFilters?: { [key: string]: string }): ExcelRow[] {
    const prependContent: ExcelRow[] = [];

    if (header?.length) {
      header.forEach(line => prependContent.push({ cells: [{ data: { type: 'String', value: line } }] }));
      prependContent.push({ cells: [] });
    }

    const filters = api.getFilterModel();
    if (Object.keys(filters).length) {
      prependContent.push({ cells: [{ data: { type: 'String', value: 'FILTROS' }, mergeAcross: 1 }] });
      Object.entries(filters)
        .map(([key, filter]) => AgFilterUtils.filterToRowMapper(key, filter))
        .forEach(row => prependContent.push(row));
    }

    if (extraFilters) {
      Object.entries(extraFilters).forEach(([key, value]) => {
        prependContent.push({
          cells: [{ data: { type: 'String', value: key }, mergeAcross: 1 }, { data: { type: 'String', value: value } }],
        });
      });
    }

    return prependContent;
  }

  static filterToRowMapper(name: string, filter: AgFilterModel): ExcelRow {
    const cells: ExcelCell[] = [];

    // Filter name
    let field = name;
    const autoColumnIndex = name.indexOf('AutoColumn-');
    if (autoColumnIndex >= 0) {
      field = name.substring(autoColumnIndex + 'AutoColumn-'.length) + ' (Grupo)';
    }
    cells.push({ data: { type: 'String', value: field } });

    // Combined filters
    const combinedMaybe = filter as ICombinedSimpleModel<ISimpleFilterModel>;
    const { operator, conditions } = combinedMaybe;
    if (operator) {
      console.assert(conditions.length == 2, conditions.length, 'not 2');

      this._singleCellMapper(conditions[0]).forEach(cell => cells.push(cell));
      cells.push({ data: { type: 'String', value: operator == 'AND' ? 'E' : 'OU' } });
      this._singleCellMapper(conditions[1]).forEach(cell => cells.push(cell));
    }

    // Single filter
    else {
      this._singleCellMapper(filter).forEach(cell => cells.push(cell));
    }

    return { cells };
  }

  private static _singleCellMapper(filter: AgFilterModel): ExcelCell[] {
    const setFilter = filter as SetFilterModel;
    const numberFilter = filter as NumberFilterModel;
    const dateFilter = filter as DateFilterModel;
    const textFilter = filter as TextFilterModel;

    switch (filter.filterType) {
      case 'set':
        return [
          { data: { type: 'String', value: '=' } },
          { data: { type: 'String', value: setFilter.values.join(', ') } },
        ];
      case 'number':
        return [
          { data: { type: 'String', value: COMPARISON_TEXT[numberFilter.type] } },
          {
            data: {
              type: 'String',
              value: numberFilter.filter + (numberFilter.filterTo == null ? '' : ' até ' + numberFilter.filterTo),
            },
          },
        ];
      case 'date':
        return [
          { data: { type: 'String', value: COMPARISON_TEXT[dateFilter.type] } },
          {
            data: {
              type: 'String',
              value: dateFilter.dateFrom + (dateFilter.dateTo == null ? '' : ' até ' + dateFilter.dateTo),
            },
          },
        ];
      case 'text':
        return [
          { data: { type: 'String', value: COMPARISON_TEXT[textFilter.type] } },
          { data: { type: 'String', value: textFilter.filter } },
        ];
      default:
        console.error('Conversão de filtro não implementado:', filter.filterType);
        return [];
    }
  }
}
