import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PrimengModule } from '@shared/modules';
import { AgGridActionCellRenderer } from './ag-grid-action-cell.component';

@NgModule({
  declarations: [AgGridActionCellRenderer],
  imports: [CommonModule, PrimengModule],
  exports: [AgGridActionCellRenderer],
})
export class AgGridActionCellModule {}
