import { InjectionToken } from '@angular/core';
import * as fromRouter from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'environments/environment';
import * as fromOperadoras from './/operadoras.reducers';
import * as fromLayout from './layout.reducers';
import * as fromUserProfile from './user-profile.reducers';

/**
 * ROOT REDUCERS
 */
export interface State {
  [fromOperadoras.featureKey]: fromOperadoras.State;
  [fromLayout.featureKey]: fromLayout.State;
  [fromUserProfile.featureKey]: fromUserProfile.State;
  router: fromRouter.RouterReducerState<any>;
}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State, Action>>('Root reducers token', {
  factory: () => ({
    [fromOperadoras.featureKey]: fromOperadoras.reducer,
    [fromLayout.featureKey]: fromLayout.reducer,
    [fromUserProfile.featureKey]: fromUserProfile.reducer,
    router: fromRouter.routerReducer,
  }),
});

/**
 * META REDUCERS
 */
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();
    return result;
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production && environment.logEnabled ? [logger] : [];
