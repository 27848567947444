export const AG_GRID_LOCALE_PT_BR = {
  // Set Filter
  selectAll: '(Selecionar tudo)',
  selectAllSearchResults: '(Selecionar resultados)',
  searchOoo: 'Pesquisar...',
  blanks: '(Vazio)',
  noMatches: 'Sem correspondências',

  // Number Filter & Text Filter
  filterOoo: 'Filtrar...',
  equals: 'Igual',
  notEqual: 'Diferente',
  blank: 'Vazio',
  notBlank: 'Não Vazio',
  empty: 'Escolha um',

  // Number Filter
  lessThan: 'Menor que',
  greaterThan: 'Maior que',
  lessThanOrEqual: 'Menor ou igual ',
  greaterThanOrEqual: 'Maior ou igual',
  inRange: 'No intervalo',
  inRangeStart: 'de',
  inRangeEnd: 'até',

  // Text Filter
  contains: 'Contém',
  notContains: 'Não contém',
  startsWith: 'Começa com',
  endsWith: 'Termina com',

  // Date Filter
  dateFormatOoo: 'dd-mm-yyyy',

  // Filter Conditions
  andCondition: 'E',
  orCondition: 'OU',

  // Filter Buttons
  applyFilter: 'Aplicar',
  resetFilter: 'Resetar',
  clearFilter: 'Limpar',
  cancelFilter: 'Cancelar',

  // Filter Titles
  textFilter: 'Filtro de Texto',
  numberFilter: 'Filtro de Número',
  dateFilter: 'Filtro de Data',
  setFilter: 'Filtro de Conjunto',

  // Group Column Filter
  groupFilterSelect: 'Selecione campo:',

  // Side Bar
  columns: 'Colunas',
  filters: 'Filtros',

  // columns tool panel
  pivotMode: 'Modo Pivô',
  groups: 'Grupos de Linhas',
  rowGroupColumnsEmptyMessage: 'Arraste aqui para agrupar linhas',
  values: 'Valores Agregados',
  valueColumnsEmptyMessage: 'Arraste aqui para agregar valores',
  pivots: 'Colunas Pivotadas',
  pivotColumnsEmptyMessage: 'Arraste aqui para pivotar colunas',

  // Header of the Default Group Column
  group: 'Grupo',

  // Row Drag
  rowDragRow: 'linha',
  rowDragRows: 'linhas',

  // Other
  loadingOoo: 'Carregando...',
  loadingError: 'ERRO',
  noRowsToShow: 'Nenhum registro encontrado',
  enabled: 'Habilitado',

  // Menu
  pinColumn: 'Pinar coluna',
  pinLeft: 'Pinar <<',
  pinRight: 'Pinar >>',
  noPin: 'Sem pinagem',
  valueAggregation: 'Agregação de Valores',
  noAggregation: 'Nenhum',
  autosizeThisColumn: 'Redimensionar esta coluna',
  autosizeAllColumns: 'Redimensionar todas as colunas',
  groupBy: 'Agrupar por',
  ungroupBy: 'Desagrupar por',
  addToValues: 'Adicionar ${variable} aos valores',
  removeFromValues: 'Remover ${variable} dos valores',
  addToLabels: 'Adicionar ${variable} aos pivôs',
  removeFromLabels: 'Remover ${variable} dos pivôs',
  resetColumns: 'Resetar colunas',
  expandAll: 'Expandir tudo',
  collapseAll: 'Contrair tudo',
  copy: 'Copiar',
  ctrlC: 'Ctrl+C',
  ctrlX: 'Ctrl+X',
  copyWithHeaders: 'Copiar com cabeçalhos',
  copyWithGroupHeaders: 'Copiar com grupo de cabeçalhos',
  cut: 'Recortar',
  paste: 'Colar',
  ctrlV: 'Ctrl+V',
  export: 'Exportar',
  csvExport: 'Exportar para CSV',
  excelExport: 'Exportar para Excel',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Soma',
  first: 'Primeiro',
  last: 'Último',
  min: 'Mínimo',
  max: 'Máximo',
  none: 'Nenhum',
  count: 'Contagem',
  avg: 'Média',
  filteredRows: 'Filtrados',
  selectedRows: 'Selecionados',
  totalRows: 'Total de linhas',
  totalAndFilteredRows: 'Linhas',
  more: 'Mais',
  to: 'para',
  of: 'de',
  page: 'Página',
  pageLastRowUnknown: '?',
  nextPage: 'Próxima página',
  lastPage: 'Última página',
  firstPage: 'Primeira página',
  previousPage: 'Página anterior',

  // Pivoting
  pivotColumnGroupTotals: 'Total',

  // ---------------------------------

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Gráfico e Modo Pivô',
  pivotChart: 'Gráfico de Pivô',
  chartRange: 'Gráfico de Intervalo',

  columnChart: 'Coluna',
  groupedColumn: 'Agrupado',
  stackedColumn: 'Empilhado',
  normalizedColumn: 'Empilhado 100%',

  barChart: 'Barra',
  groupedBar: 'Agrupado',
  stackedBar: 'Empilhado',
  normalizedBar: 'Empilhado 100%',

  pieChart: 'Pizza',
  pie: 'Pizza',
  doughnut: 'Rosquinha',

  line: 'Linha',

  xyChart: 'X Y (Dispersão)',
  scatter: 'Dispersão',
  bubble: 'Bolha',

  areaChart: 'Área',
  area: 'Área',
  stackedArea: 'Empilhado',
  normalizedArea: 'Empilhado 100%',

  histogramChart: 'Histograma',
  histogramFrequency: 'Frequência',

  combinationChart: 'Combinação',
  columnLineCombo: 'Coluna & Linha',
  AreaColumnCombo: 'Árae & Coluna',

  // Charts
  pivotChartTitle: 'Gráfico de Pivô',
  rangeChartTitle: 'Gráfico de Intervalo',
  settings: 'Configurações',
  data: 'Dados',
  format: 'Formato',
  categories: 'Categorias',
  defaultCategory: '(Nenhum)',
  series: 'Séries',
  xyValues: 'Valores X Y',
  paired: 'Modo Pareado',
  axis: 'Eixo',
  navigator: 'Navegador',
  color: 'Cor',
  thickness: 'Espessura',
  xType: 'Tipo X',
  automatic: 'Automático',
  category: 'Categoria',
  number: 'Número',
  time: 'Hora',
  autoRotate: 'Auto Rotação',
  xRotation: 'Rotação X',
  yRotation: 'Rotação Y',
  ticks: 'Marcas',
  width: 'Largura',
  height: 'Altura',
  length: 'Comprimento',
  padding: 'Enchimento',
  spacing: 'Espaçamento',
  chart: 'Gráfico',
  title: 'Título',
  titlePlaceholder: 'Título do gráfico - duplo clique para editar',
  background: 'Fundo',
  font: 'Fonte',
  top: 'Topo',
  right: 'Direita',
  bottom: 'Fundo',
  left: 'Esquerda',
  labels: 'Rótulos',
  size: 'Tamanho',
  minSize: 'Tamanho mínimo',
  maxSize: 'Tamanho máximo',
  legend: 'Legenda',
  position: 'Posição',
  markerSize: 'Tamanho do Marcador',
  markerStroke: 'Risco do Marcador',
  markerPadding: 'Enchimento do Marcador',
  itemSpacing: 'Espaçamento do Item',
  itemPaddingX: 'Enchimento X do Item',
  itemPaddingY: 'Enchimento Y do Item',
  layoutHorizontalSpacing: 'Espaçamento Horizontal',
  layoutVerticalSpacing: 'Espaçamento Vertical',
  strokeWidth: 'Largura do Risco',
  lineDash: 'Traço de Linha',
  offset: 'Deslocamento',
  offsets: 'Deslocamentos',
  tooltips: 'Dicass',
  callout: 'Chamada',
  markers: 'Marcadores',
  shadow: 'Sombra',
  blur: 'Esmaecimento',
  xOffset: 'Deslocamento X',
  yOffset: 'Deslocamento Y',
  lineWidth: 'Largura da Linha',
  normal: 'Normal',
  bold: 'Negrito',
  italic: 'Itálico',
  boldItalic: 'Negrito Itálico',
  predefined: 'Pré-definedo',
  fillOpacity: 'Opacidade de Preenchimento',
  strokeOpacity: 'Opacidade de Linha',
  histogramBinCount: 'Contagem de Divisões',
  columnGroup: 'Coluna',
  barGroup: 'Barra',
  pieGroup: 'Pizza',
  lineGroup: 'Linha',
  scatterGroup: 'X Y (Dispersão)',
  areaGroup: 'Área',
  histogramGroup: 'Histograma',
  combinationGroup: 'Combinação',
  groupedColumnTooltip: 'Agrupado',
  stackedColumnTooltip: 'Empilhado',
  normalizedColumnTooltip: 'Empilhado 100%',
  groupedBarTooltip: 'Agrupado',
  stackedBarTooltip: 'Empilhado',
  normalizedBarTooltip: 'Empilhado 100%',
  pieTooltip: 'Pizza',
  doughnutTooltip: 'Rosquinha',
  lineTooltip: 'Linha',
  groupedAreaTooltip: 'Área',
  stackedAreaTooltip: 'Empilhado',
  normalizedAreaTooltip: 'Empilhado 100%',
  scatterTooltip: 'Dispersão',
  bubbleTooltip: 'Bolha',
  histogramTooltip: 'Histograma',
  columnLineComboTooltip: 'Coluna & Linha',
  areaColumnComboTooltip: 'Área & Coluna',
  customComboTooltip: 'Combinação Personalizada',
  noDataToChart: 'Sem dados disponíveis para gerar o gráfico.',
  pivotChartRequiresPivotMode: 'Gráfico Pivô exibe que o Modo Pivô esteja ativo.',
  chartSettingsToolbarTooltip: 'Menu',
  chartLinkToolbarTooltip: 'Vinculado à Grid',
  chartUnlinkToolbarTooltip: 'Desvinculado da Grid',
  chartDownloadToolbarTooltip: 'Baixar Gráfico',
  seriesChartType: 'Tipo de Gráfico de Séries',
  seriesType: 'Tipo de Séries',
  secondaryAxis: 'Eixo Secundário',

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: '.',
  decimalSeparator: ',',
};
