import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OperadorasSelectors, UserProfileSelectors } from '@core/store/selectors';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { skipWhile, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-home-resolver',
  template: `Carregando...`,
})
export class HomeResolverComponent implements OnInit, OnDestroy {
  constructor(private store: Store, private router: Router) {}

  ngOnInit() {
    this.store
      .select(OperadorasSelectors.selectOperadorasLoaded)
      .pipe(
        skipWhile(loaded => !loaded),
        switchMap(() =>
          combineLatest([
            this.store.select(OperadorasSelectors.selectWorkspaceOperadora),
            this.store.select(UserProfileSelectors.selectNivelAcesso),
            this.store.select(UserProfileSelectors.selectUserIdssProfile),
            this.store.select(UserProfileSelectors.selectOperadorasVinculadas),
            this.store.select(OperadorasSelectors.selectOperadorasWorkspace),
          ])
        ),
        takeUntil(this.ngDestroyed$)
      )
      .subscribe(([workspace, nivelAccesso, perfil, operadorasVinculadas, operadorasWorkspace]) => {
        let operadora = null;

        switch (nivelAccesso) {
          case 'A':
            operadora =
              workspace?.cd_registro_ans ??
              perfil?.cd_registro_ans_preferencial ??
              operadorasWorkspace[0]?.cd_registro_ans;
            break;

          case 'O':
            operadora =
              workspace?.cd_registro_ans ??
              perfil?.cd_registro_ans_preferencial ??
              operadorasVinculadas[0]?.cd_registro_ans;
            break;
        }

        if (operadora) {
          return this.router.navigate(['/operadora', operadora]);
        }

        return this.router.navigate(['/dados-setor/idss/resultados-por-operadora']);
      });
  }

  private ngDestroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }
}
