import { Injectable } from '@angular/core';
import { LayoutActions } from '@core/store/actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { RouterSelectors } from '../selectors';

@Injectable()
export class RouterEffects {
  updateTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      concatLatestFrom(() => this.store.select(RouterSelectors.selectRouteData)),
      map(([, data]) => data['title']),
      //tap(title => this.titleService.setTitle(title)),
      map(title => LayoutActions.changePageTitle({ title }))
    )
  );

  updateBreadcrumb$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      concatLatestFrom(() => this.store.select(RouterSelectors.selectRouteData)),
      map(([, data]) => {
        const items = data['breadcrumb'] || [];
        return LayoutActions.updateBreadcrumb({ items });
      })
    )
  );

  constructor(private actions$: Actions, private store: Store) {}
}
