import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeralApi } from '@core/api/ords';
import { UsuarioPerfil, VinculoUsuarioOperadora } from '@core/api/ords/model';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { NotificationService } from '@shared/services';
import { KeycloakService } from 'keycloak-angular';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { UserProfileActions } from '../actions';
import { RouterSelectors } from '../selectors';

@Injectable()
export class UserProfileEffects {
  fullProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActions.getFullProfile),
      switchMap(() => [UserProfileActions.getIdssProfile(), UserProfileActions.getKeycloakProfile()]),
    ),
  );

  idssProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActions.getIdssProfile),
      switchMap(() => this.api.fetchPerfilCorrente()),
      map(profile => {
        let operadoras: VinculoUsuarioOperadora[] = [];
        if (profile.id_papel_acesso == 'O') {
          operadoras = profile.operadoras_vinculadas;
        }
        return { profile, operadoras };
      }),
      mergeMap(fullProfile => combineLatest([of(fullProfile), this.api.updateUltimoAcesso()])),
      map(([{ profile, operadoras }]) => UserProfileActions.getIdssProfileSuccess({ idss: profile, operadoras })),
      catchError((error: HttpErrorResponse) =>
        of(
          UserProfileActions.getIdssProfileFailure({
            status: error.status,
            message: error.message,
          }),
        ),
      ),
    ),
  );

  kcProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActions.getKeycloakProfile),
      switchMap(() => this.kc.loadUserProfile()),
      map(kc => UserProfileActions.getKeycloakProfileSuccess({ kc })),
    ),
  );

  salvarIdssProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserProfileActions.salvarDadosComplementares),
      mergeMap(({ idss }) => {
        let obs: Observable<UsuarioPerfil>;
        if (idss.cd_usuario) {
          obs = this.api.atualizarPerfilUsuario(idss);
        } else {
          obs = this.api.criarPerfilUsuario(idss);
        }
        return obs.pipe(
          map(savedProfile => UserProfileActions.salvarDadosComplementaresSuccess({ idss: savedProfile })),
          tap(() => this.notificationService.success('Dados salvo com sucesso')),
          catchError((error: HttpErrorResponse) =>
            of(
              UserProfileActions.salvarDadosComplementaresFailure({
                message: `[${error.status}] ${error.message} `,
                error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  salvarIdssProfileFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserProfileActions.salvarDadosComplementaresFailure),
        tap(({ error, message }) => {
          console.error('[Profile]', error);
          this.notificationService.error('Erro ao salvar: ' + message);
        }),
      ),
    { dispatch: false },
  );

  completarCadastro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      concatLatestFrom(() => [
        this.store.select(RouterSelectors.selectUrl),
        this.store.select(RouterSelectors.selectQueryParam('action')),
      ]),
      filter(([_, url, query]) => url.startsWith('/profile') && query === 'completar-cadastro'),
      map(() => UserProfileActions.completarCadastro()),
    ),
  );

  constructor(
    private store: Store,
    private api: GeralApi,
    private kc: KeycloakService,
    private actions$: Actions,
    private notificationService: NotificationService,
  ) {}
}
