import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

const CONFIG = {
  negativo: {
    title: 'Tendência Negativa em Relação a Prévia',
    icon: 'pi pi-arrow-down',
    color: 'red',
  },
  positivo: {
    title: 'Tendência Positiva em Relação a Prévia',
    icon: 'pi pi-arrow-up',
    color: 'green',
  },
  neutro: {
    title: 'Tendência Neutra em Relação a Prévia',
    icon: 'pi pi-minus',
    color: '#cccc00',
  },
  nulo: {
    title: 'Tendência Neutra em Relação a Prévia',
    icon: 'pi pi-minus',
    color: 'white',
  },
};

const NAO_PONTUADO = ['NP', 'NA'];
const INCONSISTENTE = ['IN', 'INC'];

@Component({
  selector: 'bl-simulador-valores-pontuacao',
  templateUrl: './simulador-valores-pontuacao.component.html',
  styleUrls: ['./simulador-valores-pontuacao.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimuladorValoresPontuacaoComponent implements OnChanges {
  @Input() vlPontuacao: any;
  @Input() idPrecisao: any;
  @Input() vlPontuacaoPrevia: any;
  @Input() pcDeltaPrevia: any;

  pontuado = true;
  inconsistente = false;
  tendencia: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (NAO_PONTUADO.includes(this.idPrecisao)) {
      this.pontuado = false;
      return;
    }
    this.pontuado = true;

    if (INCONSISTENTE.includes(this.idPrecisao)) {
      this.inconsistente = true;
      return;
    }
    this.inconsistente = false;

    let atual = this.vlPontuacao;
    let previa = this.vlPontuacaoPrevia;

    if (previa != null) {
      if (!atual) {
        this.tendencia = CONFIG.nulo;
      } else if (+atual < +previa) {
        this.tendencia = CONFIG.negativo;
      } else if (+atual > +previa) {
        this.tendencia = CONFIG.positivo;
      } else if (+atual === +previa) {
        this.tendencia = CONFIG.neutro;
      }
    }
  }
}
