import { HttpClient, HttpParams } from '@angular/common/http';

export abstract class Api {
  constructor(
    protected basePath: string,
    protected http: HttpClient,
  ) {}

  protected get<T>(
    path: string,
    params?:
      | HttpParams
      | {
          [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        },
  ) {
    return this.http.get<T>(`${this.basePath}${path}`, { params });
  }

  protected post<T>(path: string, body?: any) {
    return this.http.post<T>(`${this.basePath}${path}`, body);
  }

  protected put<T>(path: string, body?: any) {
    return this.http.put<T>(`${this.basePath}${path}`, body);
  }

  protected delete(path: string, body?: any) {
    return this.http.delete<void>(`${this.basePath}${path}`, body);
  }

  /**
   * Remove null and undefined parameters from object.
   */
  protected trimParams<T extends { [key: string]: any }>(params: T): Partial<T> {
    if (!params) {
      return null;
    }
    const trimmed = Object.entries(params).reduce((acc, [key, value]) => {
      if (value != null && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});
    return trimmed;
  }
}
