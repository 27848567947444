import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'cpf',
})
export class CPFPipe implements PipeTransform {
  @memo()
  transform(cpfValue: any) {
    if (!cpfValue) {
      return '';
    }

    const cpf = cpfValue.replace(/[^\d]/g, '');
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
}
