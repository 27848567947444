import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { OperadorasSelectModule, WebchatModule } from '@shared/components';
import { AppSharedModule, PrimengModule } from '@shared/modules';
import { AppMainComponent } from './app.main.component';
import { AppBreadcrumbComponent } from './breadcrumb/app.breadcrumb.component';
import { AppFooterComponent } from './footer/app.footer.component';
import { AppInlineMenuComponent } from './inline-menu/app.inlinemenu.component';
import { AppMenuComponent } from './menu/app.menu.component';
import { MenuService } from './menu/app.menu.service';
import { AppMenuitemComponent } from './menu/app.menuitem.component';
import { NotificacaoDialogComponent } from './notificacao-dialog/notificacao-dialog.component';
import { PoliticaPrivacidadeComponent } from './politica-privacidade/politica-privacidade.component';
import { AppTopBarComponent } from './topbar/app.topbar.component';

@NgModule({
  imports: [AppSharedModule, PrimengModule, ClipboardModule, OperadorasSelectModule, WebchatModule],
  declarations: [
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppBreadcrumbComponent,
    AppTopBarComponent,
    AppFooterComponent,
    AppInlineMenuComponent,
    NotificacaoDialogComponent,
    PoliticaPrivacidadeComponent,
  ],
  providers: [MenuService],
})
export class LayoutModule {}
