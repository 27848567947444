import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { normalizeOperadoras } from '@core/utils';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Api } from '../api';
import {
  Benchmarking,
  ComparativoTissDiops,
  HistoricoIdss,
  ModeloPublicadoAns,
  Operadora,
  OperadoraPerfil,
  PontuacaoDimensao,
  RankingAns,
  RazaoTissDiops,
  Response,
  ResultadoTree,
} from './model';

/**
 *  ORDS-API para IDSS-ANS
 */
@Injectable({ providedIn: 'root' })
export class IdssAnsApi extends Api {
  constructor(http: HttpClient) {
    super(`${environment.API_ORDS_BASE_PATH}/idss-ans`, http);
  }

  /**
   * Recupera a lista de operadoras
   *
   * @param params lista de parâmetros opcionais
   *
   * cliente:
   *  - 1 para operadoras clientes
   *  - 0 para operadoras não clientes
   */
  fetchOperadoras(params?: { cliente?: 0 | 1 }) {
    return this.get<Response<Operadora>>('/operadoras', params).pipe(
      map(response => {
        response.items = normalizeOperadoras(response.items);
        return response;
      }),
    );
  }

  /**
   * Recupera uma operadora pelo id ANS
   *
   * @param regans código da operadora
   */
  fetchOperadora(regans: string) {
    if (!regans) {
      throw new Error('Required parameter regans was null when calling fetchOperadora.');
    }
    return this.get<Operadora>(`/operadoras/${regans}`);
  }

  /**
   * Busca o histórico do IDSS de uma operadora.
   *
   * @param regans código da operadora
   */
  fetchHistoricoIdss(regans: string) {
    if (!regans) {
      throw new Error('Required parameter regans was null when calling fetchHistoricoIdss.');
    }
    return this.get<Response<HistoricoIdss>>(`/historico-idss/${regans}`);
  }

  /**
   * Busca as pontuções das dimensões do IDSS de uma operadora.
   *
   * @param regans código da operadora
   */
  fetchPontuacaoDimensoes(regans: string) {
    if (!regans) {
      throw new Error('Required parameter regans was null when calling fetchHistoricoIdss.');
    }
    return this.get<Response<PontuacaoDimensao>>(`/pontuacao-dimensao/${regans}`);
  }

  /**
   * Busca o histórico(?) do IDSS de uma operadora.
   *
   * @param regans código da operadora
   */
  fetchResultadoTree(regans: string) {
    if (!regans) {
      throw new Error('Required parameter regans was null when calling fetchHistoricoIdss.');
    }
    return this.get<Response<ResultadoTree>>(`/resultadotree/${regans}`);
  }

  /**
   * Busca o benchmarking de uma operadora.
   *
   * @param regans código da operadora
   */
  fetchBenchmarkIdssOld(regans: string) {
    return this.get<Response<Benchmarking>>(`/bench-idss/${regans}`);
  }

  /**
   * Busca o benchmarking de várias operadoras.
   *
   * @param regansList array com os códigos das operadoras
   */
  fetchBenchmarkIdss(regansList: string[], ano_base: number) {
    return this.get<Response<Benchmarking>>(`/bench-idss`, {
      regans_compare_list: regansList.join(','),
      ano_base,
    });
  }

  /**
   * Busca a dimensão do benchmarking de uma operadora.
   *
   * @param regans código da operadora
   */
  fetchBenchmarkDimensoesOld(regans: string) {
    return this.get<Response<Benchmarking>>(`/bench-dimensao/${regans}`);
  }

  /**
   * Busca a dimensão do benchmarking de várias operadoras.
   *
   * @param regansList array com os códigos das operadoras
   */
  fetchBenchmarkDimensoes(regansList: string[], ano_base: number) {
    return this.get<Response<Benchmarking>>(`/bench-dimensao`, {
      regans_compare_list: regansList.join(','),
      ano_base,
    });
  }

  /**
   * Busca os modelos publicados pela ANS das operadoras
   */
  fetchModelosPublicadosOperadoras(ib_buscar_nao_vigente?: number) {
    return this.get<Response<ModeloPublicadoAns>>(`/modelos-publicados`, {
      ib_buscar_nao_vigente,
    });
  }

  /**
   * Busca o ranking das operadoras
   *
   * @param anoBase
   */
  fetchRankingOperadora(anoBase: number) {
    return this.get<Response<RankingAns>>(`/ranking/${anoBase}`);
  }

  fetchRazaoTissDiops(regans: string) {
    return this.get<Response<RazaoTissDiops>>(`/razao-tiss-dtiss/${regans}`);
  }

  fetchDespesaAssistencialDiops(regans: string, nr_ano_base: number) {
    return this.get<Response<ComparativoTissDiops>>(`/diops/despesa-assistencial/${regans}`, { nr_ano_base });
  }

  fetchPerfilOperadora(regans: string) {
    return this.get<Response<OperadoraPerfil>>(`/perfil-operadora/${regans}`);
  }
}
