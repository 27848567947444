import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { OperadorasActions, UserProfileActions } from '@core/store/actions';
import { OperadorasSelectors, UserProfileSelectors } from '@core/store/selectors';
import { Store } from '@ngrx/store';
import { primeNgPtBR } from '@shared/modules';
import { GeralService, LoaderService } from '@shared/services';
import { GridService } from '@shared/services/grid.service';
import { LicenseManager } from 'ag-grid-enterprise';
import { loadMessages, locale } from 'devextreme/localization';
import ptBR from 'devextreme/localization/messages/pt.json';
import { PrimeNGConfig } from 'primeng/api';
import { combineLatest } from 'rxjs';
import { filter, skipWhile, switchMap } from 'rxjs/operators';

const LOCALE = 'pt-BR';
const AG_GRID_LICENSE =
  'Using_this_{AG_Grid}_Enterprise_key_{AG-054902}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{BLENDUS_Tecnologia_em_Sade}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Gestor_IDSS}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Gestor_IDSS}_need_to_be_licensed___{Gestor_IDSS}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_April_2025}____[v3]_[01]_MTc0NDQxMjQwMDAwMA==5ce291519db35088f083819c491be70c';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  constructor(
    private store: Store,
    private geralService: GeralService,
    private gridService: GridService,
    private loadService: LoaderService,
    private router: Router,
    primengConfig: PrimeNGConfig,
  ) {
    locale(LOCALE);
    loadMessages(ptBR);
    primengConfig.setTranslation(primeNgPtBR);
    primengConfig.ripple = true;

    LicenseManager.setLicenseKey(AG_GRID_LICENSE);
  }

  ngOnInit() {
    this.store.dispatch(UserProfileActions.getFullProfile());
    this.store.dispatch(OperadorasActions.getOperadoras());
    this.geralService.fetchDominios();
    this.gridService.fetchVisoes();

    // After initial loading, cancel pending requests when navigating to a page
    combineLatest([
      this.store.select(UserProfileSelectors.selectUserProfile),
      this.store.select(OperadorasSelectors.selectOperadorasLoaded),
      this.geralService.getDominiosLoaded(),
      this.gridService.getVisoesLoaded(),
    ])
      .pipe(
        // tap(_ => console.log('[App] wait for initial data loaded', _)),
        skipWhile(
          ([{ kc_loaded, idss_loaded }, operadora_loaded, dominios_loaded, visoes_loaded]) =>
            !(kc_loaded && idss_loaded && operadora_loaded && dominios_loaded && visoes_loaded),
        ),
        // tap(_ => console.log('[App] all initial data loaded', _)),
        switchMap(() => this.router.events),
        filter(event => event instanceof NavigationStart),
        // tap(_ => console.log('[App] router event :: NavigationStart => Cancel Pending Requests', _)),
      )
      .subscribe(() => this.loadService.cancelPendingRequests());
  }
}
