import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileSelectors } from '@core/store/selectors';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminCoordenadorGuard {
  constructor(private store: Store, private router: Router) {}

  canActivate() {
    return combineLatest([
      this.store.select(UserProfileSelectors.selectNivelAcesso),
      this.store.select(UserProfileSelectors.selectOperadorasCoordenadas),
    ]).pipe(
      map(([nivelAccesso, operadorasCoordenadas]) => nivelAccesso == 'A' || operadorasCoordenadas.length > 0),
      tap(ok => {
        if (!ok) {
          this.router.navigate(['access']);
        }
      })
    );
  }
}
