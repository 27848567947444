import { NgModule } from '@angular/core';
import { AppSharedModule } from '@shared/modules';
import { SimuladorValoresPontuacaoModule } from '../simulador-valores-pontuacao/simulador-valores-pontuacao.module';
import { ValorPontuacaoColumnRenderer } from './valor-pontuacao-column.component';

@NgModule({
  declarations: [ValorPontuacaoColumnRenderer],
  exports: [ValorPontuacaoColumnRenderer],
  imports: [AppSharedModule, SimuladorValoresPontuacaoModule],
})
export class ValorPontuacaoColumnModule {}
