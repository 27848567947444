import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserProfileSelectors } from '@core/store/selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileGuard {
  constructor(private store: Store, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.store.select(UserProfileSelectors.selectUserProfile).pipe(
      skipWhile(({ kc_loaded, idss_loaded }) => !kc_loaded || !idss_loaded),
      map(({ idss, isIncomplete, isOffline }) => {
        if (idss && !isIncomplete && !isOffline) {
          return true;
        }
        const path = state.url.split('?')[0];
        const action = route.queryParams.action;
        if (isOffline) {
          if (path != '/offline') {
            return this.router.createUrlTree(['/offline']);
          }
        }
        if (isIncomplete) {
          if (path != '/profile' || action != 'completar-cadastro') {
            return this.router.createUrlTree(['/profile'], { queryParams: { action: 'completar-cadastro' } });
          }
        }
        return true;
      })
    );
  }
}
