import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'competencia',
})
export class CompetenciaPipe implements PipeTransform {
  @memo()
  transform(competencia: any) {
    if (competencia == null) {
      return competencia;
    }

    const competenciaStr = '' + competencia;
    if (competenciaStr.length != 6) {
      return competenciaStr;
    }

    return competenciaStr.substring(0, 4) + '-' + competenciaStr.substring(4, 6);
  }
}
