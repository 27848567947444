import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LayoutActions, OperadorasActions } from '@core/store/actions';
import { SIDEBAR_MENU } from '@core/menu';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Injectable()
export class LayoutEffects {
  updateMenuLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperadorasActions.changeSelectedOperadoraWorkspace),
      map(({ cd_registro_ans }) => {
        const menu = SIDEBAR_MENU.map(parent => {
          const items = parent.items.map(item => {
            if (!item.routerLink) {
              return item;
            }
            const routerLink = [item.routerLink[0].replace('${regans}', cd_registro_ans)];
            return { ...item, routerLink };
          });
          return { ...parent, items };
        });

        return LayoutActions.updateMenuLinks({ menu });
      })
    )
  );

  constructor(private actions$: Actions, private store: Store, private titleService: Title) {}
}
