import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private _messageService: MessageService) {}

  clear() {
    this._messageService.clear();
  }

  info(message: string, extraConfig?: Message) {
    this.buildMessage('info', message, extraConfig);
  }

  success(message: string, extraConfig?: Message) {
    this.buildMessage('success', message, extraConfig);
  }

  warn(message: string, extraConfig?: Message) {
    this.buildMessage('warn', message, extraConfig);
  }

  error(message: string, extraConfig?: Message) {
    this.buildMessage('error', message, extraConfig);
  }

  handleError(error: Error | HttpErrorResponse, message?: string, extraConfig?: Message) {
    if (!message && error instanceof HttpErrorResponse) {
      message = error.error?.message ?? error.error?.error;
    }
    if (!message) {
      message = error.message;
    }
    this.buildMessage('error', message, extraConfig);
    return throwError(() => error);
  }

  private buildMessage(severity: string, message: string, extraConfig?: Message) {
    let detail = message;

    this._messageService.add({ severity, detail, ...(extraConfig ?? {}) });
  }
}
