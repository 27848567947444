import { NgModule } from '@angular/core';
import { AppSharedModule } from '@shared/modules';
import { OperadorasSelectModule } from '..';
import { ProfileFormComponent } from './profile-form.component';

@NgModule({
  declarations: [ProfileFormComponent],
  exports: [ProfileFormComponent],
  imports: [AppSharedModule, OperadorasSelectModule],
})
export class ProfileFormModule {}
