import { LayoutActions } from '@core/store/actions';
import { SIDEBAR_MENU } from '@core/menu';
import { createReducer, on } from '@ngrx/store';
import { MenuItem } from 'primeng/api';

const menu = SIDEBAR_MENU;

export const featureKey = 'layout';

export interface State {
  topbarTheme: string;
  menuTheme: string;
  layoutMode: string;
  menuMode: 'static' | 'overlay' | 'horizontal' | 'slim';
  inputStyle: string;
  ripple: boolean;
  menu: MenuItem[];
  breadcrumb: {
    items: MenuItem[];
    home: MenuItem;
  };
  title: string;
  fullscreen: boolean;
}

export const initialState: State = {
  topbarTheme: 'blendus',
  menuTheme: 'blendus',
  layoutMode: 'light',
  menuMode: 'static',
  inputStyle: 'outlined',
  ripple: true,
  menu,
  breadcrumb: {
    items: [],
    home: { icon: 'pi pi-home', routerLink: '/' },
  },
  title: '',
  fullscreen: false,
};

export const reducer = createReducer(
  initialState,
  on(LayoutActions.updateBreadcrumb, (state, { items }) => {
    const { breadcrumb } = state;
    return { ...state, breadcrumb: { ...breadcrumb, items } };
  }),

  on(LayoutActions.changePageTitle, (state, { title }) => {
    return { ...state, title };
  }),

  on(LayoutActions.updateMenuLinks, (state, { menu }) => {
    return { ...state, menu };
  }),

  on(LayoutActions.toggleFullscreen, (state, { toggle }) => {
    return {
      ...state,
      fullscreen: toggle ?? !state.fullscreen,
    };
  })
);
