import { LazyLoadEvent } from 'primeng/api';

export const PAGE_PARAMS_DEFAULT: PageParams = {
  offset: 0,
  limit: 100,
};

export const PAGE_PARAMS_ALL: PageParams = {
  offset: 0,
  limit: 500000,
};

export interface PageParams {
  offset: number;
  limit: number;
}

export function pageFromEvent(event?: LazyLoadEvent): PageParams | undefined {
  if (!event) {
    return undefined;
  }
  return {
    offset: event.first,
    limit: event.rows,
  };
}
