import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CpfValidator } from './cpf.validator';

const VALIDATORS = [CpfValidator];

@NgModule({
  declarations: [VALIDATORS],
  exports: [VALIDATORS],
  imports: [CommonModule],
})
export class ValidatorsModule {}
