import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidatorsModule } from '@shared/validators/validators.module';
import { BlendusPipesModule } from '../pipes/pipes.module';
import { PrimengModule } from './primeng.module';

const MODULES = [CommonModule, FormsModule, ReactiveFormsModule, BlendusPipesModule, ValidatorsModule, PrimengModule];

@NgModule({
  exports: MODULES,
})
export class AppSharedModule {}
