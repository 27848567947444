import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

/**
 * Gerencia todos os serviços referente ao contexto de autenticação do usuário.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private keycloak: KeycloakService) {}

  /**
   * Efetua o logout da aplicação e redireciona para o keyclack.
   */
  logout(redirectUri?: string): void {
    this.keycloak.logout(redirectUri);
  }
}
