import { Component } from '@angular/core';
import { LayoutSelectors } from '@core/store/selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// CSS: src\assets\sass\layout\menu\_menu_common.scss
// Data: { SIDEBAR_MENU } from '@core/menu';
// Processing: LayoutActions.updateMenuLinks in effect of OperadorasActions.changeSelectedOperadoraWorkspace

@Component({
  selector: 'app-menu',
  template: `
    <ul class="layout-menu">
      <li app-menuitem *ngFor="let item of menu$ | async; let i = index" [item]="item" [index]="i" [root]="true"></li>
    </ul>
  `,
})
export class AppMenuComponent {
  menu$: Observable<any[]> = this.store.select(LayoutSelectors.selectLayoutMenu);
  constructor(private store: Store) {}
}
