import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnoBase, DominioGrupoTuss } from '@core/types';
import { normalizePerfil } from '@core/utils';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Api } from '../api';
import {
  CampoCriticaAns,
  Cbo,
  ConfigParametro,
  CriticaDetalhe,
  Dominio,
  GridVisao,
  ItensProcedimentosTuss,
  ItensProcedimentosTussParams,
  MetodoCalculoIndicador,
  MotivoGlosa,
  Notificacao,
  Orientacao,
  PAGE_PARAMS_DEFAULT,
  PageParams,
  RegistroErro,
  Response,
  TabelaTuss,
  TermosDominios,
  TipoParametro,
  Total,
  UnidadeMedida,
  UsuarioPerfil,
  URLPreAutenticada,
  URLPreAutenticadaResponse,
} from './model';

const MODULE_PATH = '/geral';

export const SKIP_HEADER = 'X-Skip-Interceptor';

/**
 *  ORDS-API para Simulador
 */
@Injectable({ providedIn: 'root' })
export class GeralApi extends Api {
  constructor(http: HttpClient) {
    super(`${environment.API_ORDS_BASE_PATH}${MODULE_PATH}`, http);
  }

  fetchPerfilCorrente() {
    return this.get<UsuarioPerfil>(`/perfil`).pipe(map(perfil => normalizePerfil(perfil)));
  }

  criarPerfilUsuario(idss: UsuarioPerfil) {
    return this.post<UsuarioPerfil>(`/perfil`, idss).pipe(map(perfil => normalizePerfil(perfil)));
  }

  atualizarPerfilUsuario(idss: UsuarioPerfil) {
    return this.put<UsuarioPerfil>(`/perfil`, idss).pipe(map(perfil => normalizePerfil(perfil)));
  }

  updateUltimoAcesso() {
    return this.post<void>(`/perfil/ultimo_acesso`);
  }

  fetchDominios() {
    return this.get<Response<Dominio>>('/config/dominios').pipe(map(resp => resp.items));
  }

  fetchDominioGrupoTuss() {
    return this.get<Response<DominioGrupoTuss>>('/ptiss/grupos-tuss').pipe(map(resp => resp.items));
  }

  /**
   * Retorna os Anos Bases de uma operadora
   */
  fetchAnosBases(regans: string) {
    const actualPath = '/config/anos-bases/operadora/' + regans;
    return this.get<Response<AnoBase>>(actualPath).pipe(map(resp => resp.items.map(item => item.anobase)));
  }

  /**
   * Busca os dados complementares da critica ANS pelo código da crítica.
   */
  fetchCamposByNrCampo(nr_campo?: number) {
    const params = {};
    if (nr_campo) {
      params['nr_campo'] = nr_campo;
    }
    return this.get<Response<CampoCriticaAns>>('/ptiss/campos', params);
  }

  fetchCBOs() {
    return this.get<Response<Cbo>>('/ptiss/cbos');
  }

  fetchMotivosGlosa() {
    return this.get<Response<MotivoGlosa>>('/ptiss/motivos-glosa');
  }

  fetchTermosDominios() {
    return this.get<Response<TermosDominios>>('/ptiss/termos-dominios');
  }

  fetchTabelas(params?: Partial<Pick<TabelaTuss, 'ib_tuss'>>) {
    const trimmed = this.trimParams(params);
    return this.get<Response<TabelaTuss>>('/ptiss/tabelas', trimmed);
  }

  fetchCamposCriticas() {
    return this.get<Response<CampoCriticaAns>>('/ptiss/campos-criticas');
  }

  fetchItensProcedimentosTuss(params: ItensProcedimentosTussParams, page: PageParams = PAGE_PARAMS_DEFAULT) {
    const trimmed = this.trimParams(params);
    return this.get<Response<ItensProcedimentosTuss>>('/ptiss/itens-procedimentos-tuss', {
      ...trimmed,
      ...page,
    });
  }

  fetchUnidadesMedida() {
    return this.get<Response<UnidadeMedida>>('/ptiss/unidades-medida');
  }

  countItensProcedimentosTuss(params: ItensProcedimentosTussParams) {
    const trimmed = this.trimParams(params);
    return this.get<Total>('/ptiss/itens-procedimentos-tuss/conta_total', trimmed);
  }

  fetchURLPreAutenticada() {
    return this.get<Response<URLPreAutenticada>>(`/config/arquivos/url-pre-autenticada`).pipe(
      map(response => response.items[0]?.url),
    );
  }

  createURLPreAutenticada() {
    return this.post<URLPreAutenticadaResponse>(`/config/arquivos/url-pre-autenticada`);
  }

  fetchParametroConfig(cd_parametro: TipoParametro) {
    return this.get<Response<ConfigParametro>>(`/config/parametro/${cd_parametro}`).pipe(
      map(response => response.items[0].vl_parametro),
    );
  }

  fetchOperadoraModulos(regans: string, ano_base: number) {
    return this.get<{ ds_modulos: string }>(`/config/modulos/operadora/${regans}`, { ano_base });
  }

  fetchDetalheCritica(cd_critica: number) {
    return this.get<Response<CriticaDetalhe>>(`/criticas`, { cd_critica });
  }

  fetchOrientacao(cd_orientacao: string) {
    return this.get<Orientacao>(`/config/orientacoes/${cd_orientacao}`);
  }

  fetchMetodoCalculoIndicador(cd_indicador: string) {
    return this.get<MetodoCalculoIndicador>(`/indicadores/${cd_indicador}/metodo-calculo-proposicoes`);
  }

  fetchGridVisoes() {
    // console.log('[Grid] fetchGridVisoes');
    return this.get<Response<GridVisao>>(`/config/visoes`);
  }

  createGridVisao(visao: GridVisao) {
    // console.log('[Grid] createGridVisao', visao);
    return this.post<GridVisao>(`/config/visoes`, visao);
  }

  fetchGridVisao(cd_visao: string) {
    // console.log('[Grid] fetchGridVisao', cd_visao);
    return this.get<GridVisao>(`/config/visoes/${cd_visao}`);
  }

  updateGridVisao(visao: GridVisao) {
    // console.log('[Grid] updateGridVisao', visao);
    return this.put<void>(`/config/visoes/${visao.cd_visao}`, visao);
  }

  deleteGridVisao(visao: GridVisao) {
    // console.log('[Grid] deleteGridVisao', visao);
    return this.delete(`/config/visoes/${visao.cd_visao}`);
  }

  reorderGridVisoes(visoes: GridVisao[]) {
    // console.log('[Grid] reorderGridVisoes', visoes);
    const body = {
      cd_visoes: visoes.map(v => v.cd_visao),
    };
    return this.put<void>(`/config/visoes`, body);
  }

  fetchNotificacoes(params?: NotificacaoParams) {
    // console.log('[Geral] fetchNotificacoes', params);
    const trimmed = this.trimParams(params);
    return this.get<Response<Notificacao>>(`/notificacoes`, trimmed);
  }

  marcarNotificacaoLida(notificacao: Notificacao) {
    // console.log('[Geral] marcarNotificacaoLido', notificacao);
    return this.post<void>(`/notificacoes/${notificacao.cd_notificacao}/lida`);
  }

  marcarNotificacaoArquivada(notificacao: Notificacao) {
    // console.log('[Geral] marcarNotificacaoArquivada', notificacao);
    return this.post<void>(`/notificacoes/${notificacao.cd_notificacao}/arquiva`);
  }

  getZendeskJwtToken() {
    return this.get<{ token: string }>(`/jwt_sign_zendesk`);
  }

  registrarErro(erro: RegistroErro) {
    const trimmed = this.trimParams(erro);
    return this.http.post<void>(`${this.basePath}/erros`, trimmed, { headers: { [SKIP_HEADER]: 'true' } });
  }
}

export interface NotificacaoParams {
  ib_arquivado?: number;
}
