import { NgModule } from '@angular/core';
import { AppSharedModule } from '@shared/modules';
import { LinksExternosComponent } from './links-externos.component';

@NgModule({
  declarations: [LinksExternosComponent],
  exports: [LinksExternosComponent],
  imports: [AppSharedModule],
})
export class LinksExternosModule {}
