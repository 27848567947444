import { NgModule } from '@angular/core';
import { AppSharedModule } from '@shared/modules';
import { PesquisarInputComponent } from './pesquisar-input.component';

@NgModule({
  declarations: [PesquisarInputComponent],
  exports: [PesquisarInputComponent],
  imports: [AppSharedModule],
})
export class PesquisarInputModule {}
