import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/auth/guard';
import { OperadoraWorkspaceGuard } from '@core/auth/guard/operadora-workspace.guard';
import { ProfileGuard } from '@core/auth/guard/profile.guard';
import { AppMainComponent } from './layout/app.main.component';
import { LogoutComponent } from './pages/public/logout/logout.component';
import { AppAccessdeniedComponent } from './pages/public/accessdenied/app.accessdenied.component';
import { AppErrorComponent } from './pages/public/error/app.error.component';
import { AppNotfoundComponent } from './pages/public/notfound/app.notfound.component';
import { HomeResolverComponent } from 'app/home-resolver.omponent';
import { AppOfflineComponent } from './pages/public/offline/app.offline.component';
import { AdminCoordenadorGuard } from '@core/auth/guard/admin-coordenador.guard';
import { ReadyGuard } from '@core/guard/ready.guard';

const PAGE_DATA = {
  dashboard: {
    data: {
      title: 'Dashboard',
    },
  },
  guiaTiss: {
    data: {
      title: 'Guia TISS',
      breadcrumb: [{ label: 'Guia TISS' }],
    },
  },
  outrasRemuneracoes: {
    data: {
      title: 'Outras Remunerações',
      breadcrumb: [{ label: 'Outras Remunerações' }],
    },
  },
  poc: {
    data: {
      title: 'Poc',
      breadcrumb: [{ label: 'Poc' }],
    },
  },
  operadoras: {
    data: {
      title: 'Operadoras do Sistema',
      breadcrumb: [{ label: 'Operadoras do Sistema' }],
    },
  },
  arquivos: {
    data: {
      title: 'Arquivos',
      breadcrumb: [{ label: 'Arquivos' }],
    },
  },
  simuladorIdss: {
    data: {
      title: 'Simulador IDSS',
      breadcrumb: [{ label: 'Simulador IDSS' }],
    },
  },
  analiseIndicadores: {
    data: {
      title: 'Cubos de Análise (Antigo)',
      breadcrumb: [{ label: 'Cubos de Análise (Antigo)' }],
    },
  },
  cubosAnalise: {
    data: {
      title: 'Cubos de Análise',
      breadcrumb: 'Cubos de Análise',
    },
  },
  dadosSetor: {
    data: {
      title: 'Dados do Setor',
      breadcrumb: [{ label: 'Dados do Setor' }],
    },
  },
  usuarios: {
    data: {
      title: 'Usuários',
      breadcrumb: [{ label: 'Usuários' }],
    },
  },
  profile: {
    data: {
      title: 'Meu Perfil',
      breadcrumb: [{ label: 'Meu Perfil' }],
    },
  },
  minhaOperadora: {
    data: {
      title: 'Minha Operadora',
      breadcrumb: [{ label: 'Minha Operadora' }],
    },
  },
};

const APP_ROUTES: Routes = [
  {
    path: '',
    component: AppMainComponent,
    canActivate: [AuthGuard, ProfileGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeResolverComponent,
        // redirectTo: 'dados-setor/idss/resultados-por-operadora',
      },
      {
        path: 'operadora/:id',
        canActivate: [OperadoraWorkspaceGuard, ReadyGuard],
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        children: [
          {
            path: '',
            data: PAGE_DATA.dashboard.data,
            loadChildren: () => import('./pages/private/dashboard/dashboard.module').then(m => m.DashboardModule),
          },
          {
            path: 'guias-tiss',
            data: PAGE_DATA.guiaTiss.data,
            loadChildren: () => import('./pages/private/guias-tiss/guias-tiss.module').then(m => m.GuiasTissModule),
          },
          {
            path: 'outras-remuneracoes',
            data: PAGE_DATA.outrasRemuneracoes.data,
            loadChildren: () =>
              import('./pages/private/outras-remuneracoes/outras-remuneracoes.module').then(
                m => m.OutrasRemuneracoesModule,
              ),
          },
          {
            path: 'poc',
            data: PAGE_DATA.poc.data,
            loadChildren: () => import('./pages/private/poc/poc.module').then(m => m.PocModule),
          },
          {
            path: 'arquivos',
            data: PAGE_DATA.arquivos.data,
            loadChildren: () => import('./pages/private/arquivos/arquivos.module').then(m => m.ArquivosModule),
          },
          {
            path: 'simulador-idss',
            data: PAGE_DATA.simuladorIdss.data,
            loadChildren: () =>
              import('./pages/private/simulador-idss/simulador-idss.module').then(m => m.SimuladorIdssModule),
          },
          {
            path: 'simulador/cubos-analise',
            data: PAGE_DATA.cubosAnalise.data,
            loadChildren: () =>
              import('./pages/private/cubos-analise/cubos-analise.module').then(m => m.CubosAnaliseModule),
          },
          {
            path: 'simulador/analises',
            loadChildren: () => import('./pages/private/analises/analises.module').then(m => m.AnalisesModule),
          },
          {
            path: 'simulador/relatorios-personalizados',
            loadChildren: () =>
              import('./pages/private/relatorios-personalizados/relatorios-personalizados.module').then(
                m => m.RelatoriosPersonalizadosModule,
              ),
          },
          {
            path: 'minha-operadora',
            data: PAGE_DATA.minhaOperadora.data,
            loadChildren: () =>
              import('./pages/private/minha-operadora/minha-operadora.module').then(m => m.MinhaOperadoraModule),
          },
        ],
      },
      {
        path: 'dados-setor',
        data: PAGE_DATA.dadosSetor.data,
        loadChildren: () => import('./pages/private/dados-setor/dados-setor.module').then(m => m.DadosSetorModule),
      },
      {
        canActivate: [AdminCoordenadorGuard],
        path: 'operadoras',
        data: PAGE_DATA.operadoras.data,
        loadChildren: () => import('./pages/private/operadoras/operadoras.module').then(m => m.OperadorasModule),
      },
      {
        canActivate: [AdminCoordenadorGuard],
        path: 'usuarios',
        data: PAGE_DATA.usuarios.data,
        loadChildren: () => import('./pages/private/usuarios/usuarios.module').then(m => m.UsuariosModule),
      },
      {
        path: 'profile',
        data: PAGE_DATA.profile.data,
        loadChildren: () => import('./pages/private/user-profile/user-profile.module').then(m => m.UserProfileModule),
      },
    ],
  },
  { path: 'offline', component: AppOfflineComponent },
  { path: 'error', component: AppErrorComponent },
  { path: 'access', component: AppAccessdeniedComponent },
  { path: 'notfound', component: AppNotfoundComponent },
  { path: 'logout', component: LogoutComponent },
  { path: '**', redirectTo: '/notfound' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      scrollPositionRestoration: 'enabled',
      // enableTracing: true, // debug only
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
