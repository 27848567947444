<p-dropdown
  styleClass="operadoras-select font-medium flex align-items-center"
  [options]="operadoras"
  [ngModel]="selectedOperadora"
  optionLabel="sg_operadora"
  [virtualScroll]="true"
  [virtualScrollItemSize]="47"
  [filter]="true"
  [filterBy]="criterioFiltro"
  (onChange)="onSelectOperadora($event)"
  placeholder="Selecione uma Operadora"
  [disabled]="disable"
  [showClear]="showClear"
>
  <ng-template pTemplate="selectedItem">
    <div *ngIf="selectedOperadora">
      <div>{{ selectedOperadora.sg_operadora + ' - ' + selectedOperadora.cd_registro_ans }}</div>
    </div>
  </ng-template>
  <ng-template let-operadora pTemplate="item">
    <div class="dropdown-item">
      <div class="operadora-analista-container">
        <div class="cd-operadora">{{ operadora.cd_registro_ans }}</div>
        <div class="nm_analista_responsavel" *ngIf="nivelAcesso == 'A'">{{ operadora.nm_analista_responsavel }}</div>
      </div>
      <div>{{ operadora.sg_operadora }}</div>
      <div class="nm-operadora">{{ operadora.nm_operadora }}</div>
    </div>
  </ng-template>
</p-dropdown>
