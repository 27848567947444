import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromOperadoras from '../reducers/operadoras.reducers';

export const selectOperadorasState = createFeatureSelector<fromOperadoras.State>(fromOperadoras.featureKey);

export const selectOperadorasLoaded = createSelector(selectOperadorasState, state => state.loaded);

export const selectOperadorasGeral = createSelector(selectOperadorasState, state => state.operadorasGeral);

export const selectOperadorasClientes = createSelector(selectOperadorasState, state => state.operadorasCliente);

export const selectOperadorasWorkspace = createSelector(selectOperadorasState, state => state.operadorasWorkspace);

export const selectWorkspaceOperadoraId = createSelector(
  selectOperadorasState,
  state => state.selectedOperadoraWorkspace?.cd_registro_ans,
);

export const selectDadosSetorOperadoraId = createSelector(
  selectOperadorasState,
  state => state.selectedOperadoraDadosSetor?.cd_registro_ans,
);

export const selectWorkspaceOperadora = createSelector(
  selectOperadorasState,
  state => state.selectedOperadoraWorkspace,
);

export const selectDadosSetorOperadora = createSelector(
  selectOperadorasState,
  state => state.selectedOperadoraDadosSetor,
);

export const selectWorkspaceNotFound = createSelector(selectOperadorasState, state => state.workspaceNotFound);

export const selectWorkspaceOperadoraFullTitle = createSelector(selectWorkspaceOperadora, operadora => {
  const { cd_registro_ans, sg_operadora } = operadora ?? {};
  return `${sg_operadora} - ${cd_registro_ans}`;
});

export const selectWorkspaceParams = createSelector(selectOperadorasState, state => state.workspaceParams);

export const findWorkspaceOperadoraById = (id: string) =>
  createSelector(selectOperadorasState, ({ operadorasWorkspaceMap }) => operadorasWorkspaceMap[id]);

export const findOperadoraById = (id: string) =>
  createSelector(selectOperadorasState, ({ operadorasGeralMap }) => operadorasGeralMap[id]);
