import { NgModule } from '@angular/core';
import { AppSharedModule } from '@shared/modules';
import { SimuladorValoresPontuacaoComponent } from './simulador-valores-pontuacao.component';

@NgModule({
  declarations: [SimuladorValoresPontuacaoComponent],
  exports: [SimuladorValoresPontuacaoComponent],
  imports: [AppSharedModule],
})
export class SimuladorValoresPontuacaoModule {}
