import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Component } from '@angular/core';

const DEFAULT_ICON = 'pi pi-list';
const DEFAULT_TOOLTIP = 'Exibir';

@Component({
  selector: 'bl-ag-grid-action-cell',
  templateUrl: './ag-grid-action-cell.component.html',
  styleUrls: ['./ag-grid-action-cell.component.scss'],
})
export class AgGridActionCellRenderer implements ICellRendererAngularComp {
  params: ICellRendererParams & AgGridActionCellParams;

  icon: string;
  tooltip: string;

  value: string;
  displayValue: string;

  showAction: boolean;

  constructor() {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams & AgGridActionCellParams): void {
    this.updateValue(params);
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams & AgGridActionCellParams) {
    this.updateValue(params);
    return true;
  }

  updateValue(params: ICellRendererParams & AgGridActionCellParams) {
    this.params = params;
    this.value = params.value;
    this.displayValue = params.valueFormatted ?? params.value;

    this.icon = params.actionIcon ?? DEFAULT_ICON;
    this.tooltip = params.actionTooltip ?? DEFAULT_TOOLTIP;

    this.showAction = true;
    if (params.node.rowPinned || params.node.footer) {
      if (!params.showActionForFooter) {
        this.showAction = false;
        return;
      }
    }
    if (!this.displayValue) {
      if (!params.showActionForEmptyValue) {
        this.showAction = false;
        return;
      }
    }
    if (params.showActionCallback) {
      this.showAction = params.showActionCallback(params.value, params.data, params.extraParams);
    }
  }

  onButtonClick() {
    this.params.actionFunction(this.value, this.params.data, this.params.extraParams);
  }
}

export interface AgGridActionCellParams {
  actionFunction: (value: any, data: any, extraParams?: any) => void;
  actionTooltip?: string;
  actionIcon?: string;
  extraParams?: any;
  showActionCallback?: (value: any, data: any, extraParams?: any) => boolean;
  showActionForEmptyValue?: boolean;
  showActionForFooter?: boolean;
}
