import { NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDropDownBoxModule,
  DxFileUploaderModule,
  DxFormModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxNumberBoxModule,
  DxPivotGridModule,
  DxPopoverModule,
  DxPopupModule,
  DxResponsiveBoxModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSpeedDialActionModule,
  DxTabPanelModule,
  DxTagBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTooltipModule,
  DxTreeListModule,
  DxValidationGroupModule,
  DxValidatorModule,
  DxContextMenuModule,
} from 'devextreme-angular';

@NgModule({
  exports: [
    DxCheckBoxModule,
    DxTagBoxModule,
    DxFormModule,
    DxPopupModule,
    DxSpeedDialActionModule,
    DxTreeListModule,
    DxNumberBoxModule,
    DxValidatorModule,
    DxValidationGroupModule,
    DxChartModule,
    DxTextAreaModule,
    DxTabPanelModule,
    DxLoadPanelModule,
    DxButtonModule,
    DxFileUploaderModule,
    DxDataGridModule,
    DxPivotGridModule,
    DxDropDownBoxModule,
    DxLoadIndicatorModule,
    DxSelectBoxModule,
    DxResponsiveBoxModule,
    DxTooltipModule,
    DxScrollViewModule,
    DxTextBoxModule,
    DxContextMenuModule,
    DxPopoverModule,
  ],
})
export class DevExtremeModule {}
