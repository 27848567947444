<div class="notificacao">
  <p-dialog
    [(visible)]="visible"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [style]="{ width: '60vw', height: '50vh' }"
    (onHide)="onDialogClose($event)"
  >
    <ng-template pTemplate="header">
      <div class="flex align-items-center justify-content-between w-full">
        <div class="flex align-items-center notificacao-tags">
          <p-tag
            styleClass="mr-2"
            [value]="tipo?.ds_valor_valido"
            [style]="{ background: tipo?.nr_cor_hexa_fundo }"
            [attr.data-cor]="tipo?.nr_cor_hexa_fundo"
          ></p-tag>
          <p-tag
            *ngIf="notificacao.dt_arquivamento"
            icon="pi pi-folder"
            value="Arquivado"
            [style]="{ color: '#aaa', background: '#eee', fontSize: '0.75rem' }"
          ></p-tag>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="body">
      <div class="bg-bluegray-50 border-round-sm h-full">
        <div class="text-xl font-medium p-2">{{ notificacao?.ds_assunto }}</div>
        <div class="notificacao-content bg-bluegray-50 p-2" [innerHTML]="notificacao?.ds_detalhe | safeHtml"></div>
      </div>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="flex align-items-end justify-content-between my-3">
        <div>
          <button
            pButton
            title="Fechar"
            type="button"
            icon="pi pi-times"
            class="p-button-outlined"
            (click)="onDialogClose()"
            label="Fechar"
          ></button>
          <button
            *ngIf="!notificacao.dt_arquivamento"
            pButton
            title="Arquivar"
            icon="pi pi-folder-open"
            class="p-button-secondary p-button-outlined p-button-text"
            (click)="arquivar()"
            label="Arquivar e Fechar"
          ></button>
        </div>
        <div class="fs-small text-bluegray-300">
          Notificação {{ notificacao?.dt_publicacao | date : 'dd/MM/yyyy HH:mm' }}
        </div>
      </div>
    </ng-template>
  </p-dialog>
</div>
