import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Notificacao, Operadora, Usuario, VinculoUsuarioOperadora } from '@core/api/ords/model';
import { AuthService } from '@core/auth/service';
import { TOPBAR_MENU, TOPBAR_MENU_ADMIN } from '@core/menu';
import { OperadorasActions } from '@core/store/actions';
import { OperadorasSelectors, RouterSelectors, UserProfileSelectors } from '@core/store/selectors';
import { PapelAcesso } from '@core/types';
import { Store } from '@ngrx/store';
import { WebchatService } from '@shared/components';
import { GeralService, NotificacaoService } from '@shared/services';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Subject, combineLatest, timer } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { AppMainComponent } from '../app.main.component';
import { PoliticaPrivacidadeComponent } from '../politica-privacidade/politica-privacidade.component';

const NOTIFICACOES_PAGE_SIZE = 5;
const NOTIFICACOES_REFRESH_INTERVAL = 5 * 60 * 1000; // 5 min

@Component({
  selector: 'app-topbar',
  styleUrls: ['./app.topbar.component.scss'],
  templateUrl: './app.topbar.component.html',
  animations: [
    trigger('topbarActionPanelAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
      ]),
      transition(':leave', [animate('.1s linear', style({ opacity: 0 }))]),
    ]),
  ],
  providers: [DialogService],
})
export class AppTopBarComponent implements OnInit, OnDestroy {
  suporteMenuItems: MenuItem[];
  dadosSetoriaisMenuItems: MenuItem[];
  administracaoMenuItems: MenuItem[];
  perfilMenuItems: MenuItem[];

  operadoras$: Observable<Operadora[] | VinculoUsuarioOperadora[]>;
  selectedOperadora$: Observable<Operadora | VinculoUsuarioOperadora>;
  perfil$: Observable<Usuario>;
  nivelAcesso$: Observable<PapelAcesso>;
  menuVisible$: Observable<boolean>;
  suporteVisible$: Observable<boolean>;

  notificacoes: Notificacao[];
  notificacoesPage: Notificacao[];
  notificacoesPageIndex: number = 0;
  notificacoesPageTotal: number;
  notificacoesNaoLidas: number;
  notificacoesFilterArquivados: boolean;

  notificacaoDialogVisible: boolean = false;
  notificacaoDialogData: Notificacao;

  constructor(
    public appMain: AppMainComponent,
    public auth: AuthService,
    private geralService: GeralService,
    private dialogService: DialogService,
    private notificacaoService: NotificacaoService,
    private webchatService: WebchatService,
    private store: Store,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.operadoras$ = this.store.select(OperadorasSelectors.selectOperadorasWorkspace);
    this.selectedOperadora$ = this.store.select(OperadorasSelectors.selectWorkspaceOperadora);
    this.perfil$ = this.store.select(UserProfileSelectors.selectUserIdssProfile);
    this.nivelAcesso$ = this.store.select(UserProfileSelectors.selectNivelAcesso);

    this.menuVisible$ = combineLatest([this.nivelAcesso$, this.selectedOperadora$]).pipe(
      map(([nivelAcesso, operadora]) => nivelAcesso != 'B' && !!operadora),
    );

    this.suporteVisible$ = this.geralService.getConfigSuporteVisible();

    this.notificacaoService
      .getNotificacoes()
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(data => {
        this.notificacoes = data.notificacoes;
        this.notificacoesNaoLidas = data.notificacoesNaoLidas.length;
        this.notificacoesPageTotal = Math.ceil(this.notificacoes.length / NOTIFICACOES_PAGE_SIZE);
        if (this.notificacoesPageTotal && this.notificacoesPageIndex >= this.notificacoesPageTotal) {
          this.notificacoesPageIndex = this.notificacoesPageTotal - 1;
        }
        this.notificacaoUpdatePage();
      });

    timer(0, NOTIFICACOES_REFRESH_INTERVAL)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(() => this.notificacoesReload());

    this.dadosSetoriaisMenuItems = TOPBAR_MENU;

    this.administracaoMenuItems = TOPBAR_MENU_ADMIN;

    this.suporteMenuItems = [
      {
        label: 'Documentação',
        items: [
          {
            label: 'Gestor IDSS',
            icon: 'mdi mdi-book-open-page-variant-outline',
            url: 'https://blendus.zendesk.com/hc/pt-br/categories/17784004969869-Documenta%C3%A7%C3%A3o-Gestor-IDSS',
            target: '_blank',
          },
          {
            label: 'FAQs',
            icon: 'mdi mdi-lightbulb-question-outline',
            url: 'https://blendus.zendesk.com/hc/pt-br/categories/17574029212941-FAQ',
            target: '_blank',
          },
          {
            label: 'Capacitações',
            icon: 'mdi mdi-google-classroom',
            url: 'https://blendus.zendesk.com/hc/pt-br/categories/18221763286029-Capacita%C3%A7%C3%B5es',
            target: '_blank',
          },
        ],
      },
      {
        label: 'Atendimento',
        items: [
          {
            label: 'Enviar Solicitação',
            icon: 'mdi mdi-send-outline',
            url: 'https://blendus.zendesk.com/hc/pt-br/signin?return_to=https%3A%2F%2Fblendus.zendesk.com%2Fhc%2Fpt-br%2Frequests%2Fnew', // 'https://blendus.zendesk.com/hc/pt-br/requests/new',
            target: '_blank',
          },
          {
            label: 'Solicitações',
            icon: 'mdi mdi-list-box-outline',
            url: 'https://blendus.zendesk.com/hc/pt-br/requests',
            target: '_blank',
          },
          {
            label: 'Chat',
            icon: 'mdi mdi-chat-outline',
            command: () => this.webchatService.showWebchat(),
          },
        ],
      },
    ];

    this.perfilMenuItems = [
      {
        label: 'Meu Perfil',
        icon: 'mdi mdi-account-edit-outline',
        routerLink: '/profile',
      },
      {
        label: 'Política de Privacidade',
        icon: 'mdi mdi-shield-account-outline',
        command: () => this.showPoliticaPrivacidade(),
      },
      {
        label: 'Política de Segurança da Informação',
        icon: 'mdi mdi-shield-lock-outline',
        url: 'assets/lgpd/politica-seguranca-informacao.pdf',
        target: '_blank',
      },
      {
        label: 'Sair',
        icon: 'mdi mdi-exit-to-app',
        command: () => this.doLogout(),
      },
    ];
  }

  showPoliticaPrivacidade() {
    // console.log('[AppTopBar] showPoliticaPrivacidade');
    this.dialogService.open(PoliticaPrivacidadeComponent, {
      header: 'Política de Privacidade',
      width: '80vw',
    });
  }

  /* Notificações */

  notificacaoToggleArquivados() {
    this.notificacoesFilterArquivados = !this.notificacoesFilterArquivados;
    this.notificacoesReload();
  }

  notificacoesReload() {
    this.notificacaoService.fetchNotificacoes({ ib_arquivado: this.notificacoesFilterArquivados ? 1 : null });
  }

  notificacaoPagePrev($event: Event) {
    this.notificacoesPageIndex--;
    this.notificacaoUpdatePage($event);
  }

  notificacaoPageNext($event: Event) {
    this.notificacoesPageIndex++;
    this.notificacaoUpdatePage($event);
  }

  private notificacaoUpdatePage($event?: Event) {
    if (!this.notificacoesPageTotal) {
      this.notificacoesPage = [];
      return;
    }

    const start = this.notificacoesPageIndex * NOTIFICACOES_PAGE_SIZE;
    const end = start + NOTIFICACOES_PAGE_SIZE;
    this.notificacoesPage = this.notificacoes.slice(start, end);
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  showNotification(notificacao: Notificacao) {
    // console.log('[AppTopBar] showNotification', notificacao);
    this.notificacaoDialogData = notificacao;
    this.notificacaoDialogVisible = true;

    if (!notificacao.dt_notificacao_lida) {
      this.notificacaoService.marcarLido(notificacao);
    }
  }

  doLogout() {
    this.auth.logout(window.location.origin);
  }

  onChangeOperadora(operadora: Operadora | VinculoUsuarioOperadora): void {
    combineLatest([
      this.store.select(RouterSelectors.selectRouteParam('id')),
      this.store.select(RouterSelectors.selectUrl),
    ])
      .pipe(first())
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(([routeId, url]) => {
        // console.log('[Store] layout/app.topbar - subscribe');
        if (routeId) {
          const updateUrl = url.replace(/^\/operadora\/(\d+)/, '/operadora/' + operadora.cd_registro_ans);
          this.router.navigateByUrl(updateUrl);
        } else {
          this.store.dispatch(
            OperadorasActions.changeSelectedOperadoraWorkspace({ cd_registro_ans: operadora.cd_registro_ans }),
          );
        }
      });
  }

  private ngDestroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }
}
