import { Injectable } from '@angular/core';
import { GeralApi, NotificacaoParams } from '@core/api/ords';
import { Notificacao, NotificacaoData } from '@core/api/ords/model';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { GeralService } from './geral.service';
import { NotificationService } from './notification.service';

const DOMINIO_NOTIFICACAO = 'APL_TIPO_NOTIFICACAO';

const FAKE_TOTAL = 16;

const FAKE_TEXTO = [
  '<p><b>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sit amet convallis ante, vel tristique diam. Aenean purus urna, tristique sodales purus ut, aliquam tristique est. Sed pulvinar nisi risus, vitae tempus augue laoreet in. Donec maximus egestas neque eget consectetur. Mauris vel dolor sit amet metus mattis varius. Vivamus nec enim dictum, egestas justo eu, suscipit nisl. Proin augue ante, mattis at tincidunt non, tristique et mi. In sapien mi, luctus a lectus vitae, interdum laoreet orci. Mauris egestas facilisis lacus non dapibus. Nunc vulputate, est a laoreet tincidunt, felis diam bibendum metus, et imperdiet est lacus vulputate ex. Curabitur vel leo id quam blandit accumsan.</b></p>',
  '<p>1 - Proin quis fringilla dolor. Nam ornare dui lorem, ut sollicitudin lectus ultrices sit amet. Pellentesque ultrices neque sed scelerisque suscipit. Duis ultricies nec libero a convallis. Nullam ultrices mi at quam dictum scelerisque. Mauris est felis, pharetra rutrum felis ac, auctor rhoncus erat. Ut elementum ultricies imperdiet. Praesent id rhoncus turpis. Integer lobortis nunc nec dui condimentum, ut tempor leo aliquam. Proin urna nibh, auctor sit amet porttitor a, rhoncus vitae felis. Etiam eget ultricies nulla. Morbi aliquet venenatis justo id ullamcorper.</p>',
  '<p>2 - Etiam lobortis sagittis risus, non placerat lacus interdum fringilla. Nullam euismod odio non justo dignissim, nec elementum nibh feugiat. Nunc vestibulum tellus nec gravida porta. Nunc urna lacus, faucibus nec tincidunt quis, consequat ultricies orci. Donec auctor porttitor neque, sed luctus sem gravida ut. Interdum et malesuada fames ac ante ipsum primis in faucibus. Phasellus ut turpis vitae ligula dictum sollicitudin ac id ante. Nullam a feugiat erat. Nam tempor sem luctus, hendrerit ante et, blandit nulla. Curabitur maximus ullamcorper est vitae accumsan. Cras tincidunt tincidunt orci, in bibendum est commodo vel.</p>',
  '<p>3 - In eu est interdum risus mollis vehicula. Nullam eget congue risus. Sed porta mi vel neque pellentesque, et posuere eros laoreet. Pellentesque condimentum fermentum mi. Proin vitae est rhoncus nibh dictum cursus eget vel metus. Donec molestie urna nec lacus semper iaculis. Cras metus enim, ornare at lectus sodales, convallis semper arcu. Phasellus lectus ipsum, euismod at elementum vel, ornare vitae nisi. Phasellus semper molestie lobortis. Vivamus elit tellus, iaculis a ex eu, aliquam posuere turpis. Donec iaculis egestas neque nec interdum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus tincidunt rhoncus maximus. Ut quis lobortis lectus. Integer vel tellus sed arcu mollis porttitor vel nec odio.</p>',
  '<p>4 - Nam molestie mollis elit, sed scelerisque risus facilisis a. Vivamus aliquam nibh non tincidunt pulvinar. Vivamus placerat interdum nunc eget lobortis. Praesent auctor erat at consectetur fermentum. Nulla facilisi. In hac habitasse platea dictumst. Etiam ut diam eu enim congue pulvinar in et dolor. Nulla scelerisque eros est, ac accumsan magna hendrerit quis. Curabitur urna augue, pretium pellentesque lobortis non, bibendum tristique enim. In consequat libero ac bibendum porta. Suspendisse accumsan iaculis erat, ac efficitur sem mollis eget. Mauris pharetra ligula libero, quis facilisis augue porta sed.</p>',
];

@Injectable({
  providedIn: 'root',
})
export class NotificacaoService {
  private notificacoes = new BehaviorSubject<Notificacao[]>([]);
  private params: NotificacaoParams;
  private firstLoad: boolean = true;

  constructor(
    private geralApi: GeralApi,
    private geralService: GeralService,
    private notificationService: NotificationService
  ) {}

  fetchNotificacoes(params?: NotificacaoParams) {
    this.params = params;
    /**/
    this.geralApi
      .fetchNotificacoes(this.params)
      .pipe(map(response => response.items))
      .subscribe(notificacoes => {
        notificacoes.forEach(n => {
          n.tipo_notificacao = this.geralService.buildNomeDeDominio(n.tp_notificacao, DOMINIO_NOTIFICACAO, true);
        });
        this.notificacoes.next(notificacoes);

        if (this.firstLoad) {
          if (notificacoes.find(n => !n.dt_notificacao_lida)) {
            this.notificationService.warn('Você possui notificações não lidas');
          }
          this.firstLoad = false;
        }
      });
    /**-/
    this._fetchFakeNotificacoes();
    /**/
  }

  private _fetchFakeNotificacoes() {
    // console.log('[NotificacoesService] fetchNotificacoes', FAKE_TOTAL);
    let date = new Date();
    const notificacoes = new Array(FAKE_TOTAL).fill(0).map((_, i) => {
      const id = i + 1;
      const texto = FAKE_TEXTO.filter((_, ti) => !ti || i & (1 << (ti - 1))).join('\n');

      date = new Date(+date - 1000 * 60 * (60 * 24 * 7 * Math.random()));
      const notificacao: Notificacao = {
        cd_notificacao: id,
        ds_assunto: `Assunto #${id} - ${
          Math.random() > 0.8 ? FAKE_TEXTO[0].substring(6, 100 + Math.random() * 100) : 'Small Text'
        }`,
        dt_inclusao: date.toISOString(),
        dt_publicacao: date.toISOString(),
        ds_detalhe: texto,
        dt_notificacao_lida: null,
        tp_notificacao: i % 4,
        tp_email: 0,
        tipo_notificacao: this.geralService.buildNomeDeDominio(i % 4, DOMINIO_NOTIFICACAO, true),
      };
      // console.log('[NotificacoesService] faker:', notificacao);
      return notificacao;
    });

    this.notificacoes.next(notificacoes);
    // console.log('[NotificacoesService] fetchNotificacoes:', notificacoes);
  }

  getNotificacoes(): Observable<NotificacaoData> {
    // console.log('[NotificacoesService] getNotificacoes');
    return this.notificacoes.pipe(
      map(notificacoes => {
        const data: NotificacaoData = {
          total: notificacoes.length,
          notificacoes,
          notificacoesNaoLidas: notificacoes.filter(n => !n.dt_notificacao_lida),
        };
        return data;
      })
    );
  }

  marcarLido(notificacao: Notificacao) {
    this.geralApi.marcarNotificacaoLida(notificacao).subscribe(() => this.fetchNotificacoes(this.params));
    // this._marcarLidoFake(notificacao);
  }

  private _marcarLidoFake(notificacao: Notificacao) {
    // console.log('[NotificacoesService] marcarLido', notificacao);
    const notificacoes = this.notificacoes.value;
    const n = notificacoes.find(n => n.cd_notificacao == notificacao.cd_notificacao);
    n.dt_notificacao_lida = new Date().toISOString();
    this.notificacoes.next(notificacoes);
  }

  marcarArquivado(notificacao: Notificacao) {
    this.geralApi.marcarNotificacaoArquivada(notificacao).subscribe(() => this.fetchNotificacoes(this.params));
  }
}
