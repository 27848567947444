// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  logEnabled: false,
  KeycloakUrl: 'https://auth.idss.com.br:8443/',
  KeycloakRealm: 'dev',
  KeycloakClientId: 'dev-webapp-gestor-idss',
  API_ORDS_BASE_PATH: 'https://api.dev.idss.com.br:5443/devords/gestorapi',
  API_GO_BASE_PATH: 'https://api.dev.idss.com.br:2443/blendus-api/gestorapi',
  API_APEX_BASE_PATH: 'https://api.dev.idss.com.br:5443/devords/r/gestorapi/gestor-idss-cliente/home',
  API_APEX_ORIENTACOES_PATH: 'https://api.dev.idss.com.br:5443/devords/r/gestorapi/gestor-idss-admin/orientacoes',
};
