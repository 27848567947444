<span>
  {{ displayValue }}
  <button
    *ngIf="showAction"
    pButton
    type="button"
    class="p-button-sm p-button-text p-0"
    style="vertical-align: baseline"
    [icon]="icon"
    [pTooltip]="tooltip"
    (click)="onButtonClick()"
  ></button>
</span>
