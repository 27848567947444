import { NgModule } from '@angular/core';
import { AppSharedModule } from '@shared/modules';
import { AppAccessdeniedComponent } from './accessdenied/app.accessdenied.component';
import { AppErrorComponent } from './error/app.error.component';
import { LogoutComponent } from './logout/logout.component';
import { AppNotfoundComponent } from './notfound/app.notfound.component';
import { AppOfflineComponent } from './offline/app.offline.component';
@NgModule({
  imports: [AppSharedModule],
  declarations: [
    AppNotfoundComponent,
    AppOfflineComponent,
    AppErrorComponent,
    AppAccessdeniedComponent,
    LogoutComponent,
  ],
})
export class PublicModule {}
