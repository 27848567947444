import { NgModule } from '@angular/core';
import { AppSharedModule } from '@shared/modules';
import { CompetenciaComponent } from './competencia.component';

@NgModule({
  declarations: [CompetenciaComponent],
  exports: [CompetenciaComponent],
  imports: [AppSharedModule],
})
export class CompetenciaModule {}
