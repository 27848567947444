<div class="pages-body error-page flex flex-column">
  <div class="align-self-center mt-auto mb-auto">
    <div class="pages-panel card flex flex-column">
      <div class="pages-header px-3 py-1">
        <h2>Serviço Inacessível</h2>
      </div>
      <div class="card mt-3 px-6">
        <img src="assets/layout/images/pages/error.png" alt="" />
      </div>
      <div class="pages-detail pb-6">
        <p>Não foi possível conectar com o serviço.</p>
        <p>
          Favor entrar em contato junto a equipe de TI para verificar<br />
          o acesso ao seguinte endereço: <strong>https://api.idss.com.br:443</strong>
        </p>
      </div>
      <button
        pButton
        pRipple
        type="button"
        label="VOLTAR PARA O SISTEMA"
        [routerLink]="['/']"
        class="p-button-text"
      ></button>
    </div>
  </div>
</div>
