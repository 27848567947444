import { NgModule } from '@angular/core';
import { AppSharedModule } from '@shared/modules';
import { WebchatComponent } from './webchat.component';
import { WebchatService } from './webchat.service';

@NgModule({
  declarations: [WebchatComponent],
  exports: [WebchatComponent],
  imports: [AppSharedModule],
  providers: [WebchatService],
})
export class WebchatModule {}
