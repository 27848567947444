import { Component, OnDestroy } from '@angular/core';
import { AgGridState, GridVisao } from '@core/api/ords/model';
import { GeralService, NotificationService } from '@shared/services';
import { GridService } from '@shared/services/grid.service';
import { IToolPanelAngularComp } from 'ag-grid-angular';
import { ColDef, IToolPanelParams } from 'ag-grid-community';
import { environment } from 'environments/environment';
import { ConfirmationService } from 'primeng/api';
import { Subject, catchError, debounceTime, takeUntil, tap } from 'rxjs';
import { CLEAR_VIEW } from '../ag-grid-utils/ag-grid.factory';
import { AgViewsToolpanelParams } from './ag-views-toolpanel.models';

const TOOLTIP_KEY = 'AG_GRID_VISOES';

export interface Params extends IToolPanelParams, AgViewsToolpanelParams {
  columns: ColDef[];
}

@Component({
  selector: 'ag-views-toolpanel',
  templateUrl: './ag-views-toolpanel.component.html',
  styleUrls: ['./ag-views-toolpanel.component.scss'],
})
export class AgViewsToolpanelComponent implements IToolPanelAngularComp, OnDestroy {
  readonly ORIENTACOES_EDIT_URL = environment.API_APEX_ORIENTACOES_PATH;

  tooltipKey = TOOLTIP_KEY;
  tooltipHtml = TOOLTIP_KEY;
  tooltipUrl = '';

  params: Params;
  resetColumns: ColDef[];

  gridKey: string;
  viewsList: GridVisao[] = [];

  inputName: string = '';
  viewEditing: GridVisao = null;
  reorderDebouncer$ = new Subject<void>();

  dragStartIndex: number = -1;
  dragEnterIndex: number = -1;

  constructor(
    private service: GridService,
    private geralService: GeralService,
    private notification: NotificationService,
    private confirmation: ConfirmationService,
  ) {
    // console.log('[ViewPanel] constructor');
  }

  agInit(params: Params): void {
    // console.log('[ViewPanel] agInit', params, this.gridKey);
    this.params = params;
    this.resetColumns = params.columns.map(col => ({
      ...col,
      ...CLEAR_VIEW,
    }));

    this.gridKey = params.gridKey;
    this.service
      .getVisoes(params.gridKey)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(visoesData => (this.viewsList = visoesData.visoes));

    this.reorderDebouncer$
      .pipe(
        debounceTime(3000),
        tap(() =>
          this.service.reorderVisoes(this.viewsList).subscribe(() => this.notification.info('Visões reordenadas')),
        ),
        takeUntil(this.ngDestroyed$),
      )
      .subscribe();

    this.geralService.getOrientacao(this.tooltipKey).subscribe(({ ds_orientacao, ds_url_documentacao }) => {
      this.tooltipHtml = ds_orientacao;
      this.tooltipUrl = ds_url_documentacao;
    });
  }

  refresh(params: IToolPanelParams<any, any, any>): boolean | void {
    return false;
  }

  getState(): AgGridState {
    // console.log('[View] getState', this.params.api.getColumnState());
    const columns = this.params.api.getColumnState().filter(col => {
      const colId = col.colId;
      if (colId.startsWith('ag-Grid-AutoColumn-')) {
        return true;
      }
      if (colId.startsWith('pivot_')) {
        return false;
      }
      if (colId.startsWith('PivotRowTotal_')) {
        return false;
      }
      return true;
    });
    // console.log('[View] filtered columns', columns);

    const state: AgGridState = {
      pivot: this.params.api.isPivotMode(),
      filters: this.params.api.getFilterModel(),
      columns,
    };
    return state;
  }

  createView() {
    const state = this.getState();
    const view: GridVisao = {
      cd_visao: null,
      ib_predefinida: 0,
      ib_fixado: 0,
      cd_grade: this.gridKey,
      nm_visao: this.inputName,
      nr_ordem: this.viewsList.length + 1,
      cl_config_visao: JSON.stringify(state),
      state: state,
    };

    this.service
      .createVisao(view)
      .pipe(catchError(error => this.notification.handleError(error)))
      .subscribe(savedView => {
        this.notification.success(`Visão '${savedView.nm_visao}' adicionada com sucesso`);
        this.inputName = '';
        this.viewEditing = null;
      });
  }

  updateView() {
    const state = this.getState();

    const view = {
      ...this.viewEditing,
      nm_visao: this.inputName,
      cl_config_visao: JSON.stringify(state),
      state: state,
    };

    this.service
      .updateVisao(view)
      .pipe(catchError(error => this.notification.handleError(error)))
      .subscribe(visaoAtual => {
        this.notification.success(`Visão '${visaoAtual.nm_visao}' atualizada com sucesso`);
        this.inputName = '';
        this.viewEditing = null;
      });
  }

  cancelEditView() {
    this.viewEditing = null;
    this.inputName = '';
  }

  editView(view: GridVisao) {
    this.service.applyVisao(view, this.params);
    this.viewEditing = view;
    this.inputName = view.nm_visao;
  }

  removeView(view: GridVisao) {
    this.confirmation.confirm({
      message: `Deseja realmente excluir a visão '${view.nm_visao}' ?`,
      header: 'Confirmação de Exclusão',
      icon: 'pi pi-info-circle',
      accept: () =>
        this.service
          .deleteVisao(view)
          .pipe(catchError(error => this.notification.handleError(error)))
          .subscribe(() => this.notification.success(`Visão '${view.nm_visao}' removida com sucesso`)),
    });
  }

  onDragStart(index: number) {
    this.dragStartIndex = index;
  }

  onDragEnter(index: number) {
    this.dragEnterIndex = index;
  }

  onDrop(dropIndex: number) {
    if (dropIndex != this.dragStartIndex) {
      const view = this.viewsList[this.dragStartIndex];
      this.viewsList.splice(this.dragStartIndex, 1);
      this.viewsList.splice(dropIndex, 0, view);
      this.reorderDebouncer$.next();
    }

    this.dragStartIndex = -1;
    this.dragEnterIndex = -1;
  }

  resetView() {
    // console.log('[ViewPanel] resetView', this.gridKey);
    this.params.api.updateGridOptions({
      pivotMode: false,
      columnDefs: this.resetColumns,
    });

    this.params.api.expandAll();

    this.service.setVisaoSync(this.gridKey, null);
  }

  onPinnedToggle(view: GridVisao) {
    // console.log('[View] onPinnedToggle', view.ib_fixado, view);

    this.service
      .updateVisao(view)
      .pipe(catchError(error => this.notification.handleError(error)))
      .subscribe(() => {
        this.inputName = '';
        this.viewEditing = null;
      });
  }

  // afterGuiAttached(params?: IAfterGuiAttachedParams): void {}

  private ngDestroyed$ = new Subject<void>();
  ngOnDestroy() {
    // console.log('[ViewPanel] ngOnDestroy', this.gridKey);
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }
}
