import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { WebchatService } from './webchat.service';

@Component({
  selector: 'bl-webchat',
  templateUrl: './webchat.component.html',
  styleUrls: ['./webchat.component.scss'],
})
export class WebchatComponent {
  showIcon$: Observable<boolean>;
  unreadCount$: Observable<number>;

  constructor(private service: WebchatService) {
    this.showIcon$ = this.service.getShowIcon();
    this.unreadCount$ = this.service.getUnreadCount();
  }

  hideIcon() {
    // console.log('[Webchat] hideIcon');
    this.service.hideIcon();
  }

  openWebchat() {
    // console.log('[Webchat] openWebchat');
    this.service.showWebchat();
  }
}
