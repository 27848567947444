import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

const CPF_PATTERN = /^(\d{3})\.?(\d{3})\.?(\d{3})-?(\d{2})$/;
const CNPJ_PATTERN = /^(\d{2})\.?(\d{3})\.?(\d{3})\/?(\d{4})-?(\d{2})$/;

@Pipe({
  name: 'cpfCnpj',
})
export class CpfCnpjPipe implements PipeTransform {
  @memo()
  transform(value: string, blankInvalid = false) {
    if (!value) {
      return value;
    }

    const cpfMatch = value.match(CPF_PATTERN);
    if (cpfMatch) {
      return `${cpfMatch[1]}.${cpfMatch[2]}.${cpfMatch[3]}-${cpfMatch[4]}`;
    }

    const cnpjMatch = value.match(CNPJ_PATTERN);
    if (cnpjMatch) {
      return `${cnpjMatch[1]}.${cnpjMatch[2]}.${cnpjMatch[3]}/${cnpjMatch[4]}-${cnpjMatch[5]}`;
    }

    return blankInvalid ? '' : value;
  }
}
