import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _isLoading = new BehaviorSubject(false);
  private _cancelPendingRequests = new Subject<void>();

  public readonly isLoading$ = this._isLoading.asObservable();
  public readonly cancelPendingRequests$ = this._cancelPendingRequests.asObservable();

  constructor() {}

  cancelPendingRequests() {
    // console.log('[LoaderService] cancelPendingRequests');
    this._cancelPendingRequests.next();
  }

  isLoading(status: boolean) {
    this._isLoading.next(status);
  }
}
