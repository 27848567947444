import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { PAGE_PARAMS_DEFAULT, PageParams } from '@core/api/ords/model';

const PAGE_NAV_WIDTH: number = 2;
const PAGE_SIZE_OPTIONS: number[] = [25, 50, 100, 500, 1000];

@Component({
  selector: 'bl-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() data: any[];
  @Input() total: number;

  @Input() page: number = 0;
  @Output() pageChange = new EventEmitter<number>();

  @Input() pageSize: number = PAGE_SIZE_OPTIONS[0];
  @Output() pageSizeChange = new EventEmitter<number>();

  @Input() reset: boolean;
  @Output() resetChange = new EventEmitter<boolean>();

  @Output() onChange = new EventEmitter<Page>();

  minPage = 0;
  maxPage = 0;

  pageSizeOptions = PAGE_SIZE_OPTIONS.map(value => ({ label: value + ' linhas', value }) as SelectItem<number>);
  pageNavOptions: number[] = [];

  lastPage = -1;
  lastOffset = -1;
  lastOffsetReached = false;
  lastOffsetFound = false;

  ngOnChanges(changes: SimpleChanges): void {
    console.log('[Pagination] changes', changes);

    if (changes['reset'] && this.reset) {
      this.doReset();
      return;
    }

    if (changes['total'] || changes['data'] || changes['pageSize']) {
      this.findLastPage();
    }

    this.refreshPageNavigation();
  }

  findLastPage() {
    // console.log('[Pagination] findLastPage');

    // Last offset provided
    if (this.total) {
      this.lastOffset = this.total;
      this.lastOffsetFound = true;
      this.lastOffsetReached = true;
      this.lastPage = Math.floor(this.lastOffset / this.pageSize);
      const pageOffset = this.lastOffset % this.pageSize;
      if (pageOffset == this.pageSize) {
        this.lastPage--;
      }
      return;
    }

    // No data, do nothing
    if (!this.data) {
      // console.log('[Pagination] findLastPage - no data');
      return;
    }

    // Empty data, current page is after last page
    if (!this.data.length) {
      // console.log('[Pagination] findLastPage - empty data, after last page');

      if (!this.lastOffsetReached) {
        this.lastOffset = this.page * this.pageSize;
      }
      this.lastOffsetReached = true;

      const offset = this.page * this.pageSize;
      if (this.lastOffset < 0 || offset < this.lastOffset) {
        this.lastOffset = offset;
      } else if (offset == this.lastOffset) {
        console.log('[Pagination] findLastPage - empty data, offset = lastOffset !!!');
        // this.lastOffsetReached = true;
      }
    }

    // Incomplete page, current page is last page
    else if (this.data.length < this.pageSize) {
      // console.log('[Pagination] findLastPage - incomplete data, current last page');

      this.lastOffsetReached = true;
      this.lastOffsetFound = true;
      this.lastOffset = this.page * this.pageSize + this.data.length;
      this.lastPage = this.page;
    }

    // Complete page, current page is before last page
    else {
      // console.log('[Pagination] findLastPage - complete page, before last page');

      const offset = this.page * this.pageSize;
      if (this.lastOffset < 0 || this.lastOffset < offset) {
        this.lastOffset = offset;
      }
    }

    // Re-calculate last page
    this.lastPage = Math.floor(this.lastOffset / this.pageSize);
    const pageOffset = this.lastOffset % this.pageSize;
    if (pageOffset == this.pageSize) {
      this.lastPage--;
    }
    // console.log('[Pagination] findLastPage - recalculate last page: ', this.lastPage, this.lastOffset, this.pageSize);
  }

  doReset() {
    // console.log('[Pagination] doReset');

    this.reset = false;

    this.page = 0;
    this.minPage = 0;
    this.maxPage = 0;

    this.lastPage = -1;
    this.lastOffset = -1;
    this.lastOffsetReached = false;
    this.lastOffsetFound = false;
  }

  goToPage(page: number) {
    // console.log('[Pagination] goToPage', page);
    this.page = page;
    this.pageChange.emit(this.page);
    this.refreshPageNavigation();
    this.onPageChange();
  }

  refreshPageNavigation() {
    // console.log('[Pagination] refreshPageNavigation', this.page, this.pageSize, this.data);

    // Standard navigation width
    this.minPage = this.page - PAGE_NAV_WIDTH;
    this.maxPage = this.page + PAGE_NAV_WIDTH;

    // Correct minPage min boundary
    if (this.minPage < 0) {
      this.minPage = 0;
    }

    // Increase maxPage width if minPage is less then standard width
    this.maxPage += PAGE_NAV_WIDTH - (this.page - this.minPage);

    // Correct maxPage max boundary, if last page is reached
    if (this.lastOffsetReached) {
      if (this.maxPage > this.lastPage) {
        this.maxPage = this.lastPage;

        // Increase minPage width if maxPage is less then standard width
        this.minPage -= PAGE_NAV_WIDTH - (this.maxPage - this.page);

        // Correct minPage min boundary again
        if (this.minPage < 0) {
          this.minPage = 0;
        }
      }
    }

    // Construct navigation options
    this.pageNavOptions = [];
    for (let i = this.minPage; i <= this.maxPage; i++) {
      this.pageNavOptions.push(i);
    }
  }

  onPageChange() {
    const offset = this.page * this.pageSize;
    let endOffset = offset + this.pageSize;
    if (this.lastOffsetFound && this.page == this.lastPage) {
      endOffset = this.lastOffset;
    }
    this.onChange.emit({
      page: this.page,
      pageSize: this.pageSize,
      limit: this.pageSize,
      offset,
      endOffset,
    });
  }

  onPageSizeChanged() {
    // console.log('[Pagination] onPageSizeChanged', this.pageSize);

    this.pageSizeChange.emit(this.pageSize);

    // reset current page
    this.page = 0;
    this.pageChange.emit(this.page);

    this.refreshPageNavigation();
    this.onPageChange();
  }
}

export interface Page extends PageParams {
  page: number;
  pageSize: number;
  endOffset?: number;
}

export const PAGE_DEFAULT: Page = {
  offset: 0,
  limit: PAGE_PARAMS_DEFAULT.limit,
  page: 0,
  pageSize: PAGE_PARAMS_DEFAULT.limit,
};
