<div class="layout-topbar">
  <div class="layout-topbar-left">
    <a class="layout-topbar-logo justify-content-start pl-3" routerLink="/">
      <img id="app-logo" src="assets/images/logo.svg" height="34" alt="Gestor IDSS" />
    </a>

    <a
      *ngIf="menuVisible$ | async"
      class="layout-menu-button"
      style="width: 2rem; height: 2rem; margin-top: -1rem; background-color: #00303d; border: 1px solid #001c24"
      (click)="appMain.onMenuButtonClick($event)"
      pTooltip="Recolher e expandir menu lateral"
      tooltipPosition="bottom"
      pRipple
    >
      <i class="pi pi-angle-right"></i>
    </a>

    <a class="layout-topbar-mobile-button" (click)="appMain.onTopbarMobileButtonClick($event)" pRipple>
      <i class="pi pi-ellipsis-v fs-large"></i>
    </a>
  </div>

  <div class="layout-topbar-right" [ngClass]="{ 'layout-topbar-mobile-active': appMain.mobileTopbarActive }">
    <div class="layout-topbar-actions-left">
      <div class="flex align-items-center pr-3" *ngIf="(operadoras$ | async).length">
        <bl-operadoras-select
          [operadoras]="operadoras$ | async"
          [selectedOperadora]="selectedOperadora$ | async"
          (operadoraChanged)="onChangeOperadora($event)"
        ></bl-operadoras-select>
        <i
          *ngIf="selectedOperadora$ | async as operadora"
          class="pi pi-copy text-white hover:text-300 cursor-pointer ml-3"
          style="font-size: 1.5rem"
          [cdkCopyToClipboard]="operadora.cd_registro_ans"
          pTooltip="Copiar código da operadora"
        ></i>
      </div>
    </div>
    <div class="layout-topbar-actions-right mr-4">
      <ul class="layout-topbar-items">
        <!-- Notification -->
        <li class="layout-topbar-item notifications">
          <a
            title="Notificações"
            class="layout-topbar-action rounded-circle p-overlay-badge"
            (click)="appMain.onTopbarItemClick($event, 'notifications')"
          >
            <i class="pi pi-bell fs-large"></i>
            <p-badge
              *ngIf="notificacoesNaoLidas"
              [value]="notificacoesNaoLidas"
              severity="warning"
              styleClass="mr-3 mt-3"
            ></p-badge>
          </a>
          <ul
            class="layout-topbar-action-panel border-round-sm shadow-4 -mt-2 overflow-auto"
            [@topbarActionPanelAnimation]="'visible'"
            *ngIf="appMain.activeTopbarItem === 'notifications'"
          >
            <li *ngIf="notificacoesPageTotal < 1" class="m-3 notificacao-vazio">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 16 16">
                  <path
                    d="M5.164 14H15c-.299-.199-.557-.553-.78-1-.9-1.8-1.22-5.12-1.22-6 0-.264-.02-.523-.06-.776l-.938.938c.02.708.157 2.154.457 3.58.161.767.377 1.566.663 2.258H6.164l-1 1zm5.581-9.91a3.986 3.986 0 0 0-1.948-1.01L8 2.917l-.797.161A4.002 4.002 0 0 0 4 7c0 .628-.134 2.197-.459 3.742-.05.238-.105.479-.166.718l-1.653 1.653c.02-.037.04-.074.059-.113C2.679 11.2 3 7.88 3 7c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0c.942.19 1.788.645 2.457 1.284l-.707.707zM10 15a2 2 0 1 1-4 0h4zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75L.625 15.625z"
                  />
                </svg>
              </div>
              <div class="mt-3 mb-4 fs-small">Você não tem novas notificações</div>
            </li>
            <li
              class="layout-topbar-action-item"
              *ngFor="let notificacao of notificacoesPage"
              [ngClass]="{
                'notificacao-nova': !notificacao.dt_notificacao_lida,
                'notificacao-arquivada': notificacao.dt_arquivamento
              }"
              (click)="showNotification(notificacao)"
            >
              <div class="flex flex-column" style="flex-grow: 1">
                <div class="flex align-items-center justify-content-between mb-1">
                  <span class="fs-small font-bold">{{ notificacao.tipo_notificacao }}</span>
                  <small>{{ notificacao.dt_publicacao | date : 'dd/MM/yyyy HH:mm' }}</small>
                </div>
                <span class="fs-small">
                  {{ notificacao.ds_assunto }}
                </span>
                <p-tag
                  *ngIf="notificacao.dt_arquivamento"
                  icon="pi pi-folder"
                  value="Arquivado"
                  class="mt-2"
                  [style]="{ color: '#aaa', background: '#eee', fontSize: '10px' }"
                ></p-tag>
              </div>
            </li>
            <li
              (click)="$event.preventDefault(); $event.stopPropagation()"
              class="mx-3 flex align-items-center justify-content-between notificacao-toolbar"
            >
              <div class="flex align-items-center">
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-angle-left"
                  class="p-button-primary p-button-sm p-button-text p-button-icon-only mr-2"
                  [disabled]="notificacoesPageIndex < 1"
                  (click)="notificacaoPagePrev($event)"
                ></button>
                <span class="mr-2">{{ notificacoesPageIndex + 1 }} de {{ notificacoesPageTotal }}</span>
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-angle-right"
                  class="p-button-primary p-button-sm p-button-text p-button-icon-only mr-2"
                  [disabled]="notificacoesPageIndex >= notificacoesPageTotal - 1"
                  (click)="notificacaoPageNext($event)"
                ></button>
              </div>
              <p-toggleButton
                pTooltip="Mostrar/Ocultar arquivados"
                tooltipPosition="bottom"
                styleClass="p-button-primary p-button-sm p-button-text notificacao-toolbar-arquivado"
                onIcon="pi pi-folder-open"
                offIcon="pi pi-folder"
                [ngModel]="notificacoesFilterArquivados"
                (onChange)="notificacaoToggleArquivados()"
              ></p-toggleButton>
            </li>
          </ul>
        </li>
        <!-- Suporte -->
        <li *ngIf="suporteVisible$ | async" class="layout-topbar-item suporte">
          <a title="Suporte" class="layout-topbar-action rounded-circle" (click)="suporteMenu.toggle($event)" pRipple>
            <i class="pi pi-question-circle fs-large"></i>
          </a>
        </li>
        <p-menu #suporteMenu [popup]="true" [model]="suporteMenuItems" styleClass="menu-suporte"></p-menu>
        <!-- Dados Setoriais -->
        <li class="layout-topbar-item dados-setoriais">
          <a
            title="Dados Setoriais"
            class="layout-topbar-action rounded-circle"
            (click)="dadosSetoriaisMenu.toggle($event)"
            pRipple
          >
            <svg width="22" height="22" fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="m9.89.23c-5.33 0-9.66 2.1-9.66 4.69v12.43c0 2.59 4.34 4.7 9.67 4.7h.43c-.28-.22-.53-.43-.75-.65-.43-.44-.78-.88-1.08-1.36-3.81-.3-6.33-1.81-6.33-2.69v-3.21l.4.19c1.27.62 2.85 1.07 4.58 1.3 0-.56.05-1.24.2-1.92-2.1-.31-3.88-.94-5.06-1.8l-.11-.08v-4.1l.41.23c1.76 1 4.3 1.6 6.96 1.65.01-.02.02-.03.04-.04 2.65-2.64 6.78-3.19 9.98-1.37v-3.28c-.01-2.59-4.35-4.69-9.68-4.69zm0 7.46c-4.72 0-7.73-1.81-7.73-2.76s3.01-2.76 7.73-2.76 7.73 1.81 7.73 2.76-3.01 2.76-7.73 2.76z"
              />
              <path
                d="m19.87 18.51.12-.19c.53-.84.81-1.82.81-2.83 0-2.93-2.38-5.32-5.32-5.32s-5.32 2.39-5.32 5.32 2.39 5.32 5.32 5.32c1 0 1.97-.28 2.81-.8l.18-.11 3.84 3.77 1.34-1.34-3.79-3.81zm-4.38.36c-1.86 0-3.38-1.52-3.38-3.38s1.52-3.38 3.38-3.38 3.38 1.52 3.38 3.38-1.52 3.38-3.38 3.38z"
              />
            </svg>
          </a>
        </li>
        <p-menu
          #dadosSetoriaisMenu
          [popup]="true"
          [model]="dadosSetoriaisMenuItems"
          styleClass="menu-dados-setoriais"
        ></p-menu>
        <!-- Administração -->
        <ng-container *ngIf="(nivelAcesso$ | async) == 'A'">
          <li class="layout-topbar-item administracao">
            <a
              title="Administração"
              class="layout-topbar-action rounded-circle"
              (click)="administracaoMenu.toggle($event)"
              pRipple
            >
              <i class="pi pi-cog fs-large"></i>
            </a>
          </li>
          <p-menu
            #administracaoMenu
            [popup]="true"
            [model]="administracaoMenuItems"
            styleClass="menu-administracao"
          ></p-menu>
        </ng-container>
        <!-- Perfil -->
        <li class="layout-topbar-item" *ngIf="perfil$ | async as perfil">
          <a
            title="Perfil"
            class="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle"
            (click)="perfilMenu.toggle($event)"
            pRipple
          >
            <img
              [src]="perfil.im_foto || 'assets/images/user-avatar.jpg'"
              alt="avatar"
              style="width: 28px; height: 28px; border-radius: 50%"
            />
          </a>
        </li>
        <p-menu #perfilMenu [popup]="true" [model]="perfilMenuItems" styleClass="menu-perfil"></p-menu>
      </ul>
    </div>
  </div>
</div>

<bl-notificacao-dialog
  [visible]="notificacaoDialogVisible"
  [notificacao]="notificacaoDialogData"
  (onClose)="notificacaoDialogVisible = false"
></bl-notificacao-dialog>

<bl-webchat></bl-webchat>
