import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PrimengModule } from '@shared/modules';
import { AgGridPaginationStatusBar } from './ag-grid-pagination-status-bar.component';

@NgModule({
  declarations: [AgGridPaginationStatusBar],
  imports: [CommonModule, PrimengModule],
  exports: [AgGridPaginationStatusBar],
})
export class AgGridPaginationStatusBarModule {}
