import { Injectable, NgZone } from '@angular/core';
import { GeralApi } from '@core/api/ords';
import { NotificationService } from '@shared/services';
import { BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WebchatService {
  private zendeskFunction: Function;

  private token$ = new BehaviorSubject<string>(null);
  private showIcon$ = new BehaviorSubject<boolean>(false);
  private unreadCount$ = new BehaviorSubject<number>(0);

  constructor(
    private geralApi: GeralApi,
    private notification: NotificationService,
    private zone: NgZone,
  ) {
    this.zendeskFunction = window['zE'];

    if (!this.zendeskFunction) {
      return;
    }

    this.zendeskFunction('messenger:on', 'unreadMessages', count => {
      // console.log('[Webchat] on:unreadMessages', count);
      this.zone.run(() => {
        this.unreadCount$.next(count);
        if (count > 0) {
          this.showIcon$.next(true);
        }
      });
    });

    this.zendeskFunction('messenger:on', 'close', () => {
      // console.log('[Webchat] on:close');
      this.zone.run(() => this.showIcon$.next(true));
    });
  }

  private doLogin(force?: false) {
    if (this.token$.value && !force) {
      return this.token$.pipe(first());
    }

    return this.geralApi.getZendeskJwtToken().pipe(
      map(response => {
        const token = response?.token;
        if (!token) {
          console.warn('Invalid token: ', response);
          return null;
        }
        this.token$.next(token);
        this.zendeskFunction('messenger', 'loginUser', callback => callback(token));
        return token;
      }),
    );
  }

  getToken() {
    return this.token$.asObservable();
  }

  getUnreadCount() {
    return this.unreadCount$.asObservable();
  }

  getShowIcon() {
    return this.showIcon$.asObservable();
  }

  showWebchat() {
    // console.log('[Geral] showWebchat');
    this.doLogin().subscribe(response => {
      if (!response) {
        this.notification.warn('Ocorreu um erro ao abrir o Chat');
        return;
      }
      this.zendeskFunction('messenger', 'open');
    });
  }

  hideWebchat() {
    // console.log('[Geral] hideWebchat');
    this.zendeskFunction('messenger', 'close');
  }

  showIcon() {
    this.showIcon$.next(true);
  }

  hideIcon() {
    this.showIcon$.next(false);
  }
}
