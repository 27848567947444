import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';
import * as DOMPurify from 'dompurify';

/**
 * Based on: https://stackoverflow.com/a/39630507/2406724
 *
 * Security using DOMPurify.
 * Ref.: https://www.intricatecloud.io/2019/10/using-angular-innerhtml-to-display-user-generated-content-without-sacrificing-security/
 *
 * Force target blank.
 * Ref: https://github.com/cure53/DOMPurify/issues/317
 */
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      if (node.tagName === 'A') {
        node.setAttribute('target', '_blank');
      }
    });
    const sanitizedValue = DOMPurify.sanitize(value, { ADD_ATTR: ['target'] });
    return this.sanitized.bypassSecurityTrustHtml(sanitizedValue);
  }
}
