import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PrimengModule } from '@shared/modules';
import { PageCardContainerComponent } from './page-card-container.component';
import { BlendusPipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [PageCardContainerComponent],
  exports: [PageCardContainerComponent],
  imports: [CommonModule, PrimengModule, BlendusPipesModule],
})
export class PageCardContainerModule {}
