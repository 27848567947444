import { MenuItem } from 'primeng/api';

export const TOPBAR_MENU: MenuItem[] = [
  {
    label: 'IDSS do Setor',
    items: [
      {
        label: 'Resultados por Operadora',
        icon: 'mdi mdi-chart-line',
        routerLink: ['/dados-setor/idss/resultados-por-operadora'],
      },
      {
        label: 'Benchmarking',
        icon: 'mdi mdi-speedometer',
        routerLink: ['/dados-setor/idss/benchmarking'],
      },
      {
        label: 'Ranking',
        icon: 'mdi mdi-podium-gold',
        routerLink: ['/dados-setor/idss/ranking'],
      },
    ],
  },
  {
    label: 'Terminologia',
    items: [
      {
        label: 'Campos do Monitoramento TISS',
        icon: 'mdi mdi-clipboard-text-search-outline',
        routerLink: ['/dados-setor/terminologia/campos-monitoramento-tiss'],
      },
      {
        label: 'CBOs',
        icon: 'mdi mdi-professional-hexagon',
        routerLink: ['/dados-setor/terminologia/cbos'],
      },
      {
        label: 'Termos de Domínios',
        icon: 'mdi mdi-equal',
        routerLink: ['/dados-setor/terminologia/termos-dominios'],
      },
      {
        label: 'Tabelas',
        icon: 'mdi mdi-clipboard-list-outline',
        routerLink: ['/dados-setor/terminologia/tabelas'],
      },
      {
        label: 'Motivos de Glosa',
        icon: 'mdi mdi-format-list-group',
        routerLink: ['/dados-setor/terminologia/motivos-glosa'],
      },
      {
        label: 'Campos e Críticas da ANS',
        icon: 'mdi mdi-focus-field-horizontal',
        routerLink: ['/dados-setor/terminologia/campos-criticas'],
      },
      {
        label: 'Itens e Procedimentos TUSS',
        icon: 'mdi mdi-pill',
        routerLink: ['/dados-setor/terminologia/itens-procedimentos-tuss'],
      },
    ],
  },
];

export const TOPBAR_MENU_ADMIN: MenuItem[] = [
  {
    label: 'Administração',
    items: [
      {
        label: 'Operadoras',
        icon: 'mdi mdi-domain',
        routerLink: ['/operadoras'],
      },
      {
        label: 'Usuários',
        icon: 'mdi mdi-account-multiple-outline',
        routerLink: ['/usuarios'],
      },
    ],
  },
];

export const SIDEBAR_MENU: MenuItem[] = [
  {
    label: 'INÍCIO',
    items: [
      {
        label: 'Dashboard',
        icon: 'bl bl-dashboard',
        routerLink: ['/operadora/${regans}'],
      },
    ],
  },

  {
    label: 'GUIAS TISS',
    items: [
      {
        label: 'Críticas das Guias e Itens',
        icon: 'bl bl-criticas-guias-itens',
        routerLink: ['/operadora/${regans}/guias-tiss/critica-guias'],
      },
      {
        label: 'Resumo do Histórico das Críticas',
        icon: 'bl bl-criticas-guias-itens',
        routerLink: ['/operadora/${regans}/guias-tiss/resumo-historico-criticas'],
      },
      {
        label: 'Resumo de Guias',
        icon: 'bl bl-resumo-guias',
        routerLink: ['/operadora/${regans}/guias-tiss/resumo-guias'],
      },
      {
        label: 'Resumo de Itens das Guias',
        icon: 'bl bl-resumo-itens-das-guias',
        routerLink: ['/operadora/${regans}/guias-tiss/resumo-itens-guias'],
      },
      /**-/
      {
        label: 'Críticas por Competência',
        routerLink: ['/operadora/${regans}/guias-tiss/criticas-arquivos-xte'],
      },
      /**/
      {
        label: 'Retornos da ANS',
        icon: 'bl bl-retornos-ans',
        routerLink: ['/operadora/${regans}/guias-tiss/retornos-ans'],
      },
      {
        label: 'Listagem de Guias',
        icon: 'bl bl-listagem-guias',
        routerLink: ['/operadora/${regans}/guias-tiss/listagem-guias'],
      },
      {
        label: 'Lançamentos e Valores das Guias',
        icon: 'bl bl-listagem-guias',
        routerLink: ['/operadora/${regans}/guias-tiss/lancamentos-guias'],
      },
      { label: 'Resumo dos Motivos de Rejeição', disabled: true, visible: false },
      { label: 'Tabela TUSS Incorreta', disabled: true, visible: false },
    ],
  },

  {
    label: 'REMUNERAÇÕES',
    visible: false,
    items: [
      { label: 'Valores Informados - TISS', disabled: true },
      { label: 'Tabela TUSS Incorreta', disabled: true },
    ],
  },

  {
    label: 'OUTRAS REMUNERAÇÕES',
    visible: true,
    items: [
      {
        label: 'Valor Preestabelecido',
        icon: 'bl bl-valor-preestabelecido',
        routerLink: ['/operadora/${regans}/outras-remuneracoes/valor-preestabelecido'],
      },
      {
        label: 'Fornecimento Direto',
        icon: 'bl bl-fornecimento-direto',
        routerLink: ['/operadora/${regans}/outras-remuneracoes/fornecimento-direto'],
      },
      {
        label: 'Despesas Assistenciais',
        icon: 'bl bl-despesas-assistenciais',
        routerLink: ['/operadora/${regans}/outras-remuneracoes/despesas-assistenciais'],
      },
    ],
  },

  {
    label: 'SIMULADOR',
    items: [
      {
        label: 'Simulador/Projeções',
        icon: 'bl bl-simulador-projecoes',
        routerLink: ['/operadora/${regans}/simulador-idss'],
      },
      {
        label: 'Cubos de Análise',
        icon: 'bl bl-cubos-analise',
        routerLink: ['/operadora/${regans}/simulador/cubos-analise'],
      },
      {
        label: 'Análises',
        icon: 'bl bl-analises',
        routerLink: ['/operadora/${regans}/simulador/analises'],
      },
      {
        label: 'Relatórios Personalizados',
        icon: 'bl bl-relatorios-personalizados',
        routerLink: ['/operadora/${regans}/simulador/relatorios-personalizados'],
      },
      {
        label: 'Comparativo TISS e DIOPS',
        icon: 'bl bl-comparativo-tiss-diops',
        routerLink: ['/operadora/${regans}/guias-tiss/comparativo-tiss-diops'],
      },
    ],
  },

  {
    label: 'ARQUIVOS',
    items: [
      {
        label: 'Postar',
        icon: 'bl bl-postar',
        routerLink: ['/operadora/${regans}/arquivos/postar'],
      },
      {
        label: 'Lotes XTE',
        icon: 'bl bl-lotes-xte',
        routerLink: ['/operadora/${regans}/arquivos/lotes-xte'],
      },
      {
        label: 'Baixar',
        icon: 'bl bl-baixar',
        routerLink: ['/operadora/${regans}/arquivos/baixar'],
      },
      {
        label: 'Situação',
        icon: 'bl bl-situacao',
        routerLink: ['/operadora/${regans}/arquivos/situacao'],
      },
    ],
  },

  {
    label: 'MINHA OPERADORA',
    items: [
      {
        label: 'Planos de Saúde',
        icon: 'bl bl-planos-saude',
        routerLink: ['/operadora/${regans}/minha-operadora/planos-de-saude'],
      },
      {
        label: 'Pacotes e Procedimentos Próprios',
        icon: 'bl bl-pacotes-procedimentos-proprios',
        routerLink: ['/operadora/${regans}/minha-operadora/pacotes-e-procedimentos'],
      },
    ],
  },

  {
    label: 'POC',
    visible: false,
    items: [
      {
        label: 'AG Grid',
        routerLink: ['/operadora/${regans}/poc/ag-grid'],
      },
      {
        label: 'ORDS API Test',
        routerLink: ['/operadora/${regans}/poc/ords-api-test'],
      },
      {
        label: 'APEX in iframe',
        routerLink: ['/operadora/${regans}/poc/apex-iframe'],
      },
    ],
  },

  {
    label: 'AJUDA',
    visible: false,
    items: [
      {
        label: 'Documentação Arquitetura',
        url: '/assets/doc/index.html',
        target: '_blank',
      },
      {
        label: 'Tema',
        url: 'https://primefaces.org/ultima-ng/#/documentation',
        target: '_blank',
      },
    ],
  },
];
