const INTERVALO_PONTUACAO = [
  {
    text: '1,0000',
    value: ['#FIELDNAME#', '=', 1],
  },
  {
    text: '0,80 a 0,9999',
    value: [
      ['#FIELDNAME#', '>=', 0.8],
      ['#FIELDNAME#', '<', 1],
    ],
  },
  {
    text: '0,60 a 0,79',
    value: [
      ['#FIELDNAME#', '>=', 0.6],
      ['#FIELDNAME#', '<', 0.8],
    ],
  },
  {
    text: '0,40 a 0,59',
    value: [
      ['#FIELDNAME#', '>=', 0.4],
      ['#FIELDNAME#', '<', 0.6],
    ],
  },
  {
    text: '0,20 a 0,39',
    value: [
      ['#FIELDNAME#', '>=', 0.2],
      ['#FIELDNAME#', '<', 0.4],
    ],
  },
  {
    text: '0,0001 a 0,19',
    value: [
      ['#FIELDNAME#', '>', 0],
      ['#FIELDNAME#', '<', 0.2],
    ],
  },
  {
    text: 'ZERO',
    value: ['#FIELDNAME#', '=', 0],
  },
];

export class GeneralHelper {
  static calcularCompetencia(competencia, meses) {
    let mes = Number(competencia.substr(0, 2));
    let ano = Number(competencia.substr(2, 4));
    let novaCompetencia = new Date(ano, mes - 1, 1);
    novaCompetencia.setMonth(novaCompetencia.getMonth() + meses);
    let novoMes = novaCompetencia.getMonth() + 1;
    let novoMesStr;
    if (novoMes < 10) {
      novoMesStr = '0' + novoMes.toString();
    } else {
      novoMesStr = novoMes.toString();
    }
    return novoMesStr + novaCompetencia.getFullYear().toString();
  }

  static getFilterIntervaloPontuacao(fieldName = 'vl_pontuacao') {
    let intervalo = JSON.stringify(INTERVALO_PONTUACAO).replace(/#FIELDNAME#/g, fieldName);
    return JSON.parse(intervalo);
  }
}
