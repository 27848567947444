import { createAction, props } from '@ngrx/store';
import { MenuItem } from 'primeng/api';

export const updateBreadcrumb = createAction('[Layout] Update breadcrumb', props<{ items: MenuItem[] }>());

export const changePageTitle = createAction('[Layout] Change page title', props<{ title: string }>());

export const updateMenuLinks = createAction('[Layout] Update menu links', props<{ menu: MenuItem[] }>());

export const toggleFullscreen = createAction('[Layout] Toggle fullscreen', props<{ toggle?: boolean }>());
