import { HttpErrorResponse } from '@angular/common/http';
import { KeycloakProfile, Usuario, UsuarioPerfil, VinculoUsuarioOperadora } from '@core/api/ords/model';
import { createAction, props } from '@ngrx/store';

export const getFullProfile = createAction('[UserProfile] Get User Full Profile');

export const getIdssProfile = createAction('[UserProfile] Get User IDSS Profile');

export const getKeycloakProfile = createAction('[UserProfile] Get User Keycloak Profile');

export const getIdssProfileSuccess = createAction(
  '[UserProfile] User IDSS Profile -> Success',
  props<{ idss: Usuario; operadoras?: VinculoUsuarioOperadora[] }>(),
);

export const getIdssProfileFailure = createAction(
  '[UserProfile] User IDSS Profile -> Failure',
  props<{ status: number; message: string }>(),
);

export const getKeycloakProfileSuccess = createAction(
  '[UserProfile] Get User Keycloak Profile -> Success',
  props<{ kc: KeycloakProfile }>(),
);

export const completarCadastro = createAction('[UserProfile] Completar Cadastro');

export const editarCadastro = createAction('[UserProfile] Editar Cadastro');

export const cancelarEditarCadastro = createAction('[UserProfile] Cancelar Editar Cadastro');

export const salvarDadosComplementares = createAction(
  '[UserProfile] Salvar User IDSS Profile -> ...',
  props<{ idss: UsuarioPerfil }>(),
);

export const salvarDadosComplementaresSuccess = createAction(
  '[UserProfile] Salvar User IDSS Profile -> Success',
  props<{ idss: UsuarioPerfil }>(),
);

export const salvarDadosComplementaresFailure = createAction(
  '[UserProfile] Salvar User IDSS Profile -> Failure',
  props<{ message: string; error: HttpErrorResponse }>(),
);
