<div *ngIf="showIcon$ | async" id="webchat">
  <p-button
    icon="mdi mdi-chat-processing-outline fs-large"
    label="Chat"
    (click)="openWebchat()"
    styleClass="webchat-button"
  ></p-button>
  <p-badge
    *ngIf="unreadCount$ | async as count"
    [value]="count"
    severity="warning"
    styleClass="webchat-badge"
  ></p-badge>
  <p-button
    type="button"
    icon="pi pi-times"
    (click)="hideIcon()"
    styleClass="webchat-close-icon w-1rem h-1rem"
  ></p-button>
</div>
