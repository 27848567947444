import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';

const DELTA_WIDTH_THRESHOLD = 5;

@Component({
  selector: 'bl-long-column-hint',
  styleUrls: ['./long-column-hint.component.scss'],
  template: `
    <div #colDiv class="long-column-hint" [title]="hint">
      {{ value }}
    </div>
  `,
})
export class LongColumnHintComponent implements ICellRendererAngularComp, AfterViewInit, OnDestroy {
  resizeObserver: ResizeObserver;
  @ViewChild('colDiv') colDiv: ElementRef<HTMLDivElement>;

  params: ICellRendererParams;
  value: string;
  hint: string;

  constructor() {}

  ngAfterViewInit(): void {
    // console.log('[LongCol] ngAfterViewInit', this.colDiv);
    this.resizeObserver = new ResizeObserver(event => this.checkResizeHint(event));
    this.resizeObserver.observe(this.colDiv.nativeElement);
  }

  ngOnDestroy(): void {
    // console.log('[LongCol] ngOnDestroy');
    this.resizeObserver.unobserve(this.colDiv.nativeElement);
    this.resizeObserver.disconnect();
  }

  checkResizeHint(event?: ResizeObserverEntry[]) {
    // console.log('[LongCol] checkResizeHint', event);
    const offsetWidth = this.colDiv.nativeElement.offsetWidth;
    const scrollWidth = this.colDiv.nativeElement.scrollWidth;
    // console.log('offsetWidth', offsetWidth, 'scrollWidth', scrollWidth);
    this.hint = scrollWidth - offsetWidth > DELTA_WIDTH_THRESHOLD ? this.value : '';
  }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.updateValue(params);
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams) {
    this.updateValue(params);
    return true;
  }

  updateValue(params: ICellRendererParams) {
    // console.log('[LongCol] updateValue', this.colDiv);
    this.params = params;
    this.value = params.valueFormatted ?? params.value;
  }
}
