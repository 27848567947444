import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PrimengModule } from '@shared/modules';
import { BlendusPipesModule } from '@shared/pipes/pipes.module';
import { AgViewsToolpanelComponent } from './ag-views-toolpanel.component';

@NgModule({
  declarations: [AgViewsToolpanelComponent],
  imports: [CommonModule, PrimengModule, BlendusPipesModule],
  exports: [AgViewsToolpanelComponent],
})
export class AgViewsToolpanelModule {}
