import { FullProfile, VinculoUsuarioOperadora } from '@core/api/ords/model';
import { UserProfileActions } from '@core/store/actions';
import { PapelAcesso } from '@core/types';
import { createReducer, on } from '@ngrx/store';

export const featureKey = 'userProfile';

const REQUIRED_FIELDS = ['nr_cpf', 'ds_cargo', 'nr_fone_celular'];

export interface State extends FullProfile {
  kc_loaded: boolean;
  idss_loaded: boolean;
  isEditing: boolean;
  isIncomplete: boolean;
  isOffline: boolean;
  nivelAcesso: PapelAcesso;
  operadorasVinculadas: VinculoUsuarioOperadora[];
  operadorasCoordenadas: VinculoUsuarioOperadora[];
}

export const initialState: State = {
  kc: null,
  kc_loaded: false,
  idss: null,
  idss_loaded: false,
  nivelAcesso: 'B',
  operadorasVinculadas: [],
  operadorasCoordenadas: [],
  isIncomplete: true,
  isOffline: false,
  isEditing: false,
};

export const reducer = createReducer(
  initialState,

  on(UserProfileActions.getKeycloakProfileSuccess, (state, { kc }) => {
    return { ...state, kc, kc_loaded: true };
  }),

  on(UserProfileActions.getIdssProfileSuccess, (state, { idss, operadoras }) => {
    let isIncomplete = false;
    REQUIRED_FIELDS.forEach(field => {
      if (!idss[field]) isIncomplete = true;
    });

    return {
      ...state,
      idss,
      idss_loaded: true,
      isIncomplete,
      nivelAcesso: idss.id_papel_acesso ?? 'B',
      operadorasVinculadas: operadoras,
      operadorasCoordenadas: idss.id_papel_acesso == 'O' ? operadoras.filter(op => op.id_papel_acesso_ops == 'C') : [],
    };
  }),

  on(UserProfileActions.getIdssProfileFailure, (state, { status, message }) => {
    console.warn('[UserProfileActions.getIdssProfileFailure]', status, message);
    return { ...state, idss: null, idss_loaded: true, isIncomplete: status >= 400, isOffline: !status };
  }),

  on(UserProfileActions.salvarDadosComplementaresSuccess, (state, { idss }) => {
    return { ...state, idss, idss_loaded: true, isEditing: false, isIncomplete: false, isOffline: false };
  }),

  on(UserProfileActions.completarCadastro, state => {
    return { ...state, isEditing: true };
  }),

  on(UserProfileActions.editarCadastro, state => {
    return { ...state, isEditing: true };
  }),

  on(UserProfileActions.cancelarEditarCadastro, state => {
    return { ...state, isEditing: false };
  }),
);
