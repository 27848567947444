import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { OperadorasActions } from '@core/store/actions';
import { OperadorasSelectors, UserProfileSelectors } from '@core/store/selectors';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { filter, map, skipWhile, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OperadoraWorkspaceGuard {
  constructor(private store: Store, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(OperadorasSelectors.selectOperadorasLoaded).pipe(
      skipWhile(loaded => !loaded),
      switchMap(() =>
        combineLatest([
          of(route.paramMap.get('id')),
          this.store.select(OperadorasSelectors.selectWorkspaceOperadoraId),
          this.store.select(OperadorasSelectors.selectWorkspaceNotFound),
          this.store.select(UserProfileSelectors.selectNivelAcesso),
        ])
      ),
      map(([routeId, workspaceId, notFound, nivelAccesso]) => {
        if (routeId) {
          if (notFound) {
            this.store.dispatch(OperadorasActions.clearNotFound());
            this.router.navigate([nivelAccesso != 'A' ? 'access' : '/']);
            return false;
          }
          if (workspaceId != routeId) {
            this.store.dispatch(OperadorasActions.changeSelectedOperadoraWorkspace({ cd_registro_ans: routeId }));
            return null;
          }
          return true;
        }

        this.router.navigate(['/']);
        return false;
      }),
      filter(ok => ok !== null),
      take(1)
    );
  }
}
