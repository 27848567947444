import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Operadora } from '@core/api/ords/model';
import { UserProfileSelectors } from '@core/store/selectors';
import { PapelAcesso } from '@core/types';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'bl-operadoras-select',
  templateUrl: './operadoras-select.component.html',
  styleUrls: ['./operadoras-select.component.scss'],
})
export class OperadorasSelectComponent implements OnInit, OnDestroy {
  @Input() disable: boolean;
  @Input() showClear: boolean;
  @Input() operadoras: Operadora[];
  @Input() selectedOperadora: OperadorasSelectComponent;

  @Output() operadoraChanged = new EventEmitter<Operadora>();

  nivelAcesso: PapelAcesso;
  criterioFiltro: string;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(UserProfileSelectors.selectNivelAcesso)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(nivelAcesso => {
        this.nivelAcesso = nivelAcesso;
        this.updateCriterioFiltro();
      });
  }

  onSelectOperadora({ value }) {
    this.operadoraChanged.emit(value);
  }

  updateCriterioFiltro() {
    this.criterioFiltro =
      this.nivelAcesso == 'A'
        ? 'cd_registro_ans,sg_operadora,nm_operadora,nm_analista_responsavel'
        : 'cd_registro_ans,sg_operadora,nm_operadora';
  }

  private ngDestroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }
}
