import { OperadorasActions } from '@core/store/actions';
import { Operadora, OperadoraParametros, VinculoUsuarioOperadora } from '@core/api/ords/model';
// import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

export const featureKey = 'operadoras';

export interface State {
  operadorasGeral: Operadora[];
  operadorasCliente: Operadora[];
  operadorasWorkspace: Operadora[] | VinculoUsuarioOperadora[];
  operadorasGeralMap: { [key: string]: Operadora };
  operadorasClienteMap: { [key: string]: Operadora };
  operadorasWorkspaceMap: { [key: string]: Operadora | VinculoUsuarioOperadora };
  selectedOperadoraDadosSetor: Operadora | null;
  selectedOperadoraWorkspace: Operadora | VinculoUsuarioOperadora | null;
  workspaceParams: OperadoraParametros | null;
  workspaceNotFound: boolean;
  loaded: boolean;
}

export const initialState: State = {
  operadorasGeral: [],
  operadorasCliente: [],
  operadorasWorkspace: [],
  operadorasGeralMap: {},
  operadorasClienteMap: {},
  operadorasWorkspaceMap: {},
  selectedOperadoraDadosSetor: null,
  selectedOperadoraWorkspace: null,
  workspaceParams: null,
  workspaceNotFound: false,
  loaded: false,
};

export const reducer = createReducer(
  initialState,

  on(OperadorasActions.getOperadorasSuccess, (state, { operadorasGeral, operadorasClientes, operadorasWorkspace }) => {
    const operadorasGeralMap = operadorasGeral.reduce((map, op) => {
      map[op.cd_registro_ans] = op;
      return map;
    }, {});
    const operadorasClientesMap = operadorasClientes.reduce((map, op) => {
      map[op.cd_registro_ans] = op;
      return map;
    }, {});
    const operadorasWorkspaceMap: { [key: string]: Operadora | VinculoUsuarioOperadora } = {};
    for (const op of operadorasWorkspace) {
      operadorasWorkspaceMap[op.cd_registro_ans] = op;
    }
    return {
      ...state,
      operadorasGeral,
      operadorasClientes,
      operadorasWorkspace,
      operadorasGeralMap,
      operadorasClientesMap,
      operadorasWorkspaceMap,
      loaded: true,
    };
  }),

  on(OperadorasActions.changeSelectedOperadoraDadosSetorSuccess, (state, { operadora }) => {
    return { ...state, selectedOperadoraDadosSetor: operadora };
  }),

  on(OperadorasActions.changeSelectedOperadoraWorkspaceSuccess, (state, { operadora, params }) => {
    return {
      ...state,
      selectedOperadoraWorkspace: operadora,
      workspaceParams: params,
      workspaceNotFound: false,
    };
  }),

  on(OperadorasActions.changeSelectedOperadoraWorkspaceFailed, state => {
    return {
      ...state,
      selectedOperadoraWorkspace: null,
      workspaceParams: null,
      workspaceNotFound: true,
    };
  }),

  on(OperadorasActions.changeWorkspaceParams, (state, { partialParams }) => {
    const workspaceParams = { ...state.workspaceParams, ...partialParams };
    return {
      ...state,
      workspaceParams,
    };
  }),

  on(OperadorasActions.clearNotFound, state => {
    return {
      ...state,
      workspaceNotFound: false,
    };
  }),
);
