import { APP_INITIALIZER, NgModule } from '@angular/core';
import { KeycloakAngularModule, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { from } from 'rxjs';
import { environment } from '../../environments/environment';

const DEBUG_KC_EVENTS = false;

/**
 * Função de inicialização do cliente para o Keycloak.
 *
 * Ref: https://github.com/mauriciovigolo/keycloak-angular/issues/149#issuecomment-661885981
 *
 * @param keycloak Serviço do Keycloak
 */
function initializeKeycloak(keycloak: KeycloakService) {
  return () => {
    from(keycloak.keycloakEvents$).subscribe(e => {
      let eventName = '';
      switch (e.type) {
        case KeycloakEventType.OnAuthError:
          eventName = 'OnAuthError';
          break;
        case KeycloakEventType.OnAuthLogout:
          eventName = 'OnAuthLogout';
          break;
        case KeycloakEventType.OnAuthRefreshError:
          eventName = 'OnAuthRefreshError';
          break;
        case KeycloakEventType.OnAuthRefreshSuccess:
          eventName = 'OnAuthRefreshSuccess';
          break;
        case KeycloakEventType.OnAuthSuccess:
          eventName = 'OnAuthSuccess';
          break;
        case KeycloakEventType.OnReady:
          eventName = 'OnReady';
          break;
        case KeycloakEventType.OnTokenExpired:
          eventName = 'OnTokenExpired';
          if (DEBUG_KC_EVENTS) {
            console.log('[KC] refreshing token');
          }
          keycloak.updateToken(60);
          break;
      }
      if (DEBUG_KC_EVENTS) {
        console.log('[KC] event', eventName);
      }
    });

    return keycloak.init({
      config: {
        url: environment.KeycloakUrl,
        realm: environment.KeycloakRealm,
        clientId: environment.KeycloakClientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
      bearerExcludedUrls: ['https://objectstorage.sa-saopaulo-1.oraclecloud.com/*'],
    });
  };
}

@NgModule({
  imports: [KeycloakAngularModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
})
export class AppAuthModule {}
