import { Operadora, OperadoraParametros, VinculoUsuarioOperadora } from '@core/api/ords/model';
import { createAction, props } from '@ngrx/store';

export const getOperadoras = createAction('[Operadoras/API] Get operadoras');

export const getOperadorasSuccess = createAction(
  '[Operadoras/API] Get operadoras Success',
  props<{
    operadorasGeral: Operadora[];
    operadorasClientes: Operadora[];
    operadorasWorkspace: Operadora[] | VinculoUsuarioOperadora[];
  }>(),
);

export const getOperadorasFailure = createAction(
  '[Operadoras/API] Get operadoras Failure',
  props<{ errorMsg: string }>(),
);

export const changeSelectedOperadoraWorkspace = createAction(
  '[Operadoras] Altera a operadora selecionada do workspace',
  props<{ cd_registro_ans: string }>(),
);

export const changeSelectedOperadoraWorkspaceSuccess = createAction(
  '[Operadoras] Altera a operadora selecionada do workspace -> Success',
  props<{
    operadora: Operadora | VinculoUsuarioOperadora | null;
    params: OperadoraParametros;
  }>(),
);

export const changeSelectedOperadoraWorkspaceFailed = createAction(
  '[Operadoras] Altera a operadora selecionada do workspace -> Failed',
);

export const changeSelectedOperadoraDadosSetor = createAction(
  '[Operadoras] Altera a operadora selecionada dos dados do setor (query param)',
  props<{ cd_registro_ans: string }>(),
);

export const changeSelectedOperadoraDadosSetorSuccess = createAction(
  '[Operadoras] Altera a operadora selecionada dos dados do setor -> Success',
  props<{ operadora: Operadora }>(),
);

export const changeSelectedOperadoraUrl = createAction(
  '[Operadoras] Altera a url para a operadora selecionada',
  props<{ cd_registro_ans: string }>(),
);

export const changeWorkspaceParams = createAction(
  '[Operadoras] Altera os parametros do workspace atual',
  props<{ partialParams: Partial<OperadoraParametros> }>(),
);

export const clearNotFound = createAction('[Operadoras] Limpa a flag de operadora nao encontrada');
