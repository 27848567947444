import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SKIP_HEADER } from '@core/api/ords';
import { ErroInfo } from '@core/api/ords/model';
import { OperadorasSelectors } from '@core/store/selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeralService } from './geral.service';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptorService implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  private operadora: string;

  constructor(
    private loaderService: LoaderService,
    private geralService: GeralService,
    private router: Router,
    private store: Store,
  ) {
    this.store.select(OperadorasSelectors.selectWorkspaceOperadora).subscribe(operadora => {
      this.operadora = operadora?.cd_registro_ans;
    });
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('[Loader] intercept', req.url);

    if (req.headers.has(SKIP_HEADER)) {
      // console.log('[Loader] skip intercept');
      const headers = req.headers.delete(SKIP_HEADER);
      return next.handle(req.clone({ headers }));
    }

    this.requests.push(req);

    this.loaderService.isLoading(true);
    return new Observable(observer => {
      const subscription = next
        .handle(req)
        .pipe(takeUntil(this.loaderService.cancelPendingRequests$))
        .subscribe({
          next: event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          error: err => {
            // Register error only when authenticated
            if (err.status != 401) {
              this.geralService.registrarErro({
                cd_registro_ans: this.operadora,
                ds_url_api: req.urlWithParams,
                ds_url_aplicacao: this.router.routerState.snapshot.url,
                ds_erro: this.extractErrorInfo(err),
              });
            }
            this.removeRequest(req);
            observer.error(err);
          },
          complete: () => {
            this.removeRequest(req);
            observer.complete();
          },
        });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

  extractErrorInfo(err: any): string {
    const info: ErroInfo = {};
    if (err.name) {
      info.name = err.name;
    }
    if (err.message) {
      info.message = err.message;
    }
    if (err.error) {
      info.error = err.error;
    }
    if (err.status) {
      info.status = err.status;
    }
    return JSON.stringify(info);
  }
}
