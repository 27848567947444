import { NgModule } from '@angular/core';
import { AppSharedModule } from '@shared/modules';
import { OperadorasSelectComponent } from './operadoras-select.component';

@NgModule({
  declarations: [OperadorasSelectComponent],
  exports: [OperadorasSelectComponent],
  imports: [AppSharedModule],
})
export class OperadorasSelectModule {}
