import { NgModule } from '@angular/core';
import { AppSharedModule } from '@shared/modules';
import { YearSelectComponent } from './year-select.component';

@NgModule({
  declarations: [YearSelectComponent],
  exports: [YearSelectComponent],
  imports: [AppSharedModule],
})
export class YearSelectModule {}
