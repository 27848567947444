import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { LayoutSelectors, OperadorasSelectors, UserProfileSelectors } from '@core/store/selectors';
import { Store } from '@ngrx/store';
import { LoaderService } from '@shared/services/loader.service';
import { PrimeNGConfig } from 'primeng/api';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MenuService } from './menu/app.menu.service';

@Component({
  selector: 'app-main',
  templateUrl: './app.main.component.html',
})
export class AppMainComponent implements AfterViewInit, OnInit, OnDestroy {
  topbarMenuActive: boolean;

  menuActive: boolean;

  staticMenuDesktopInactive: boolean;

  mobileMenuActive: boolean;

  menuClick: boolean;

  mobileTopbarActive: boolean;

  topbarRightClick: boolean;

  topbarItemClick: boolean;

  activeTopbarItem: string;

  documentClickListener: () => void;

  configActive: boolean;

  configClick: boolean;

  menuHoverActive = false;

  searchClick = false;

  search = false;

  currentInlineMenuKey: string;

  inlineMenuActive: Record<string, boolean> = {};

  inlineMenuClick: boolean;

  layoutConfig;

  menuVisible$: Observable<boolean>;

  loading$: Observable<boolean>;

  fullscreen$: Observable<boolean>;

  constructor(
    public renderer: Renderer2,
    private menuService: MenuService,
    private primengConfig: PrimeNGConfig,
    private loaderService: LoaderService,
    private store: Store,
  ) {
    this.store
      .select(LayoutSelectors.selectLayoutState)
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe(config => {
        // console.log('[Store] layout/app.main - subscribe');
        this.layoutConfig = config;
      });

    this.menuVisible$ = combineLatest([
      this.store.select(UserProfileSelectors.selectNivelAcesso),
      this.store.select(OperadorasSelectors.selectWorkspaceOperadora),
      this.store.select(LayoutSelectors.selectFullscreen),
    ]).pipe(map(([nivelAcesso, operadora, fullscreen]) => nivelAcesso != 'B' && !!operadora && !fullscreen));

    this.fullscreen$ = this.store.select(LayoutSelectors.selectFullscreen);

    this.loading$ = this.loaderService.isLoading$;
  }

  ngOnInit() {
    this.menuActive = this.isStatic() && !this.isMobile();
  }

  ngAfterViewInit() {
    // hides the horizontal submenus or top menu if outside is clicked
    this.documentClickListener = this.renderer.listen('body', 'click', () => {
      // console.log('[Appmain] body click');
      if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.menuClick && (this.isHorizontal() || this.isSlim())) {
        this.menuService.reset();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      if (!this.menuClick) {
        if (this.mobileMenuActive) {
          this.mobileMenuActive = false;
        }

        if (this.isOverlay()) {
          this.menuActive = false;
        }

        this.menuHoverActive = false;
        this.unblockBodyScroll();
      }

      if (!this.searchClick) {
        this.search = false;
      }

      if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
        this.inlineMenuActive[this.currentInlineMenuKey] = false;
      }

      this.inlineMenuClick = false;
      this.searchClick = false;
      this.configClick = false;
      this.topbarItemClick = false;
      this.topbarRightClick = false;
      this.menuClick = false;
    });
  }

  onMenuButtonClick(event) {
    // console.log('[AppMain] onMenuButtonClick');

    this.menuActive = !this.menuActive;
    this.topbarMenuActive = false;
    this.topbarRightClick = true;
    this.menuClick = true;

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.mobileMenuActive = !this.mobileMenuActive;
      if (this.mobileMenuActive) {
        this.blockBodyScroll();
      } else {
        this.unblockBodyScroll();
      }
    }

    event.preventDefault();
  }

  onTopbarMobileButtonClick(event) {
    // console.log('[AppMain] onTopbarMobileButtonClick');
    this.mobileTopbarActive = !this.mobileTopbarActive;
    event.preventDefault();
  }

  onMenuClick($event) {
    this.menuClick = true;

    if (this.inlineMenuActive[this.currentInlineMenuKey] && !this.inlineMenuClick) {
      this.inlineMenuActive[this.currentInlineMenuKey] = false;
    }
  }

  onSearchKeydown(event) {
    if (event.keyCode === 27) {
      this.search = false;
    }
  }

  onInlineMenuClick(event, key) {
    if (key !== this.currentInlineMenuKey) {
      this.inlineMenuActive[this.currentInlineMenuKey] = false;
    }

    this.inlineMenuActive[key] = !this.inlineMenuActive[key];
    this.currentInlineMenuKey = key;
    this.inlineMenuClick = true;
  }

  onTopbarItemClick(event, item) {
    // console.log('[AppMain] onTopbarItemClick');
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    if (item === 'search') {
      this.search = !this.search;
      this.searchClick = !this.searchClick;
    }

    // event.preventDefault(); // TODO bug de click?
    // console.log('[AppMain] onTopbarItemClick -> preventDefault');
  }

  onTopbarSubItemClick(event) {
    // console.log('[AppMain] onTopbarSubItemClick -> preventDefault');
    event.preventDefault();
  }

  onRippleChange(event) {
    // this.app.ripple = event.checked;
    this.primengConfig.ripple = event.checked;
  }

  onConfigClick(event) {
    this.configClick = true;
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isMobile() {
    return window.innerWidth <= 991;
  }

  isOverlay() {
    return this.layoutConfig.menuMode === 'overlay';
  }

  isStatic() {
    return this.layoutConfig.menuMode === 'static';
  }

  isHorizontal() {
    return this.layoutConfig.menuMode === 'horizontal';
  }

  isSlim() {
    return this.layoutConfig.menuMode === 'slim';
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' ',
      );
    }
  }

  private ngDestroyed$ = new Subject<void>();
  ngOnDestroy() {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();

    if (this.documentClickListener) {
      this.documentClickListener();
    }
  }
}
