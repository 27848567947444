<div class="pages-body error-page flex flex-column">
  <div class="align-self-center mt-auto mb-auto">
    <div class="pages-panel card flex flex-column">
      <div class="pages-header px-3 py-1">
        <h2>ERRO!</h2>
      </div>
      <div class="card mt-3 px-6">
        <img src="assets/layout/images/pages/error.png" alt="" />
      </div>
      <div class="pages-detail pb-6">O recurso solicitado não está disponível.</div>
      <button
        pButton
        pRipple
        type="button"
        label="VOLTAR PARA O DASHBOARD"
        [routerLink]="['/']"
        class="p-button-text"
      ></button>
    </div>
  </div>
</div>
